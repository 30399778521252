import React, { Component } from "react";
import { BackendInterface } from "../../Helpers/BackendInterface";

import "./PrintRoomInvoice.scss";
import { showNotification } from "../../Helpers/Notify";
import dayjs from "dayjs";

class PrintRoomInvoice extends Component {
	state = {
		invoice: {},
		bookedRooms: [],
		dogPrice: 0,
		externalBreakfastSum: 0,
		rooms: [],
		externalBreakfastPrice: 0,
		dogPriceSum: 0,
		totalSum: 0,
		sonstigesSum: 0,
		nettoSum: -999,
		arrangementNetto: 0,
		invoiceNum: 0,
		roomCalcFinshed: false,
		restaurantSum: 0,
		roomsTotal: 0,
		arrangementDays: 0,
		selectedArrangement: {},
		hp3Price: 0,
		hp5Price: 0,
		taxes: {},
		bruttoSum: 0,
		contributionPrice: 0,
		ustSum: { hotel: 0, standard: 0 }
	};
	async getPrices() {
		try {
			const roomPrices = await BackendInterface.getAsyncDataByUrl("/prices/rooms");
			const breakfastPrices = await BackendInterface.getAsyncDataByUrl("/prices/breakfast");
			const taxes = (await BackendInterface.getAsyncDataByUrl("/prices/taxes"))[0];
			const dogPrice = (await BackendInterface.getAsyncDataByUrl("/prices/dog"))[0].price;
			const contributionPrice = (await BackendInterface.getAsyncDataByUrl("/prices/contribution"))[0].price;
			let invoiceNum = undefined;
			if (this.state.invoice.invoiceNumber) {
				invoiceNum = (await BackendInterface.getAsyncDataByUrl("/prices/invoiceNumber")).number;
			}
			const hp3Price = (await BackendInterface.getAsyncDataByUrl("/prices/menu/3"))[0].price;
			const hp5Price = (await BackendInterface.getAsyncDataByUrl("/prices/menu/5"))[0].price;

			this.setState(
				{
					invoiceNum,
					hp3Price,
					hp5Price,
					contributionPrice,
					taxes,
					dogPrice,
					roomPrices,
					breakfastPrices
				},
				() => {
					setTimeout(() => {
						this.print();
					}, 500);
					this.checkSelectedArrangements();
					this.getDogPrice();
				}
			);
		} catch (ex) {
			console.error(ex);
			this.exit("Anwendungsfehler aufgetreten");
		}
	}

	componentDidUpdate = (nextProps, nextState) => {
		if (this.state.nettoSum === -999) {
			this.calcNettoSum();
		}
	};

	calcNettoSum = () => {
		if (this.state.roomCalcFinshed) {
			this.setState(
				{
					nettoSum:
						this.state.roomsTotal + this.state.dogPriceSum + this.state.externalBreakfastSum + this.state.arrangementNetto,
					bruttoSum:
						this.state.roomsTotal +
						this.state.dogPriceSum +
						this.state.externalBreakfastSum +
						this.state.arrangementNetto +
						this.state.ustSum.standard +
						this.state.ustSum.hotel
				},
				() => {
					let sum = 0;
					if (this.state.invoice.hp3) {
						sum += Number((this.state.invoice.numhp3 * this.state.hp3Price).toFixed(2));
					}
					if (this.state.invoice.hp5) {
						sum += Number((this.state.invoice.numhp5 * this.state.hp5Price).toFixed(2));
					}
					sum +=
						this.state.bruttoSum +
						this.state.restaurantSum +
						this.state.sonstigesSum +
						this.state.invoice.days * this.state.invoice.abgabePerson * this.state.contributionPrice;
					this.setState({
						totalSum: sum
					});
				}
			);
		}
	};
	exit = error => {
		showNotification({
			type: "E",
			message: error
		});
		this.props.goBack();
	};
	setupBeforeUnloadListener = () => {
		window.addEventListener("beforeunload", ev => {
			this.exit(true);
		});
	};

	getDogPrice = () => {
		if (this.state.dogPrice > 0 && this.state.invoice.numdog > 0) {
			const a =
				Number((this.state.dogPrice / (1 + this.state.taxes.standard)).toFixed(2)) *
				this.state.invoice.days *
				this.state.invoice.numdog;
			const b = Number((this.state.dogPrice * this.state.invoice.days * this.state.invoice.numdog).toFixed(2));
			this.addToUst("standard", b - a);
			this.setState({
				dogPriceSum: a
			});
		}
	};
	checkSelectedArrangements = () => {
		const selectedArrangement = this.state.invoice.arrangements.find(x => x.selected === true);

		let days;
		if (!selectedArrangement) {
			days = 0;
		} else {
			days = selectedArrangement.days;
		}
		let arrangementNetto = 0;

		if (selectedArrangement.value !== "none") {
			//Calc Room Costs
			const internalBreakfastPriceBrutto = Number(this.state.breakfastPrices.find(x => x.roomType === "in").price.toFixed(2));
			const internalBreakfastPrice = Number((internalBreakfastPriceBrutto / (this.state.taxes.standard + 1)).toFixed(2));
			this.state.invoice.rooms.forEach(element => {
				let type = undefined;
				let persAnz = undefined;
				if (element.ez === true) {
					type = "ez";
					persAnz = 1;
				} else if (element.dz === true) {
					type = "dz";
					persAnz = 2;
				}

				if (type) {
					let bruttoPrice = 0;
					let nettoPrice = 0;
					if (!this.state.invoice.alreadyPaid) {
						bruttoPrice = this.state.roomPrices.find(x => x.priceCategory === element.priceCategory && x.roomType === type)
							.price;
						nettoPrice = Number((bruttoPrice / (this.state.taxes.hotel + 1)).toFixed(2));

						this.addToUst("hotel", (bruttoPrice - nettoPrice) * days);

						//Frühstück Ust
						this.addToUst("standard", (internalBreakfastPriceBrutto - internalBreakfastPrice) * persAnz * days);

						arrangementNetto += Number((nettoPrice * days + persAnz * internalBreakfastPrice * days).toFixed(2));
						// Add Menu Costs
						if (selectedArrangement.menus) {
							selectedArrangement.menus.forEach(menu => {
								const menuBruttoPrice = menu.price;
								const menuNettoPrice = Number((menuBruttoPrice / (this.state.taxes.standard + 1)).toFixed(2));
								arrangementNetto += Number((menuNettoPrice * persAnz * menu.days).toFixed(2));

								this.addToUst("standard", (menuBruttoPrice - menuNettoPrice) * persAnz * menu.days);
							});
						}
					}
				}
			});
			this.setState({
				arrangementNetto
			});
		}
		this.setState(
			{
				selectedArrangement,
				arrangementDays: days
			},
			this.calculateRoomPrices
		);
	};

	addToUst = (ust, height) => {
		const ustSum = this.state.ustSum;
		ustSum[ust] += Number(height.toFixed(2));
		this.setState({
			ustSum
		});
	};

	calculateRoomPrices = () => {
		const days = this.state.invoice.days - this.state.arrangementDays;

		if (this.state.roomPrices && this.state.invoice) {
			//Frühstück hinzufügen
			const num = Number(this.state.invoice.numChildren) + Number(this.state.invoice.numAdult);
			let externalNum = num - this.state.invoice.maxPersons;
			if (externalNum < 0) {
				externalNum = 0;
			}
			const internalBreakfastPriceBrutto = Number(this.state.breakfastPrices.find(x => x.roomType === "in").price.toFixed(2));
			const internalBreakfastPrice = Number((internalBreakfastPriceBrutto / (this.state.taxes.standard + 1)).toFixed(2));

			const rooms = [];
			this.state.invoice.rooms.forEach(element => {
				let type = undefined;
				let persAnz = undefined;
				if (element.ez === true) {
					type = "ez";
					persAnz = 1;
				} else if (element.dz === true) {
					type = "dz";
					persAnz = 2;
				}

				if (type) {
					const bruttoPrice = 0;
					const nettoPrice = 0;
					let price = 0;

					if (!this.state.invoice.alreadyPaid) {
						const bruttoPrice = this.state.roomPrices.find(
							x => x.priceCategory === element.priceCategory && x.roomType === type
						).price;
						const nettoPrice = Number((bruttoPrice / (this.state.taxes.hotel + 1)).toFixed(2));

						//Frühstück Ust
						this.addToUst("standard", (internalBreakfastPriceBrutto - internalBreakfastPrice) * persAnz * days);

						//Zimmer Ust
						this.addToUst("hotel", (bruttoPrice - nettoPrice) * days);

						price = nettoPrice + persAnz * internalBreakfastPrice;
					}

					const newObj = {
						name: element.name,
						bruttoPrice,
						nettoPrice,
						price,
						priceCategory: element.priceCategory,
						type: type.toUpperCase()
					};
					rooms.push(newObj);
				}
			});

			//Zusatzfrühstück berechnen
			const externalBreakfastPriceBrutto = Number(this.state.breakfastPrices.find(x => x.roomType === "ex").price.toFixed(2));
			const externalBreakfastPrice = Number((externalBreakfastPriceBrutto / (this.state.taxes.standard + 1)).toFixed(2));
			const externalBreakfastSum = externalNum * days * externalBreakfastPrice;

			//Frühstück Ust
			this.addToUst("standard", (externalBreakfastPriceBrutto - externalBreakfastPrice) * externalNum * days);

			this.setState({
				externalBreakfastNum: externalNum * days,
				externalBreakfastSum,
				externalBreakfastPrice
			});

			let roomsTotal = 0;
			//Raumendsumme berechnen
			rooms.forEach(room => {
				room.roomTotal = room.price * days;
				roomsTotal += room.roomTotal;
			});

			//State schreiben
			this.setState(
				{
					rooms,
					roomsTotal
				},
				() => {
					this.setState({
						roomCalcFinshed: true
					});
				}
			);
		}
	};

	calculateRestaurantInvoices = () => {
		let sum = 0;
		this.state.invoice.restaurantInvoices.forEach(element => {
			if (element.active) {
				sum += element.price;
			}
		});
		this.setState({
			restaurantSum: sum
		});
	};

	calculateSonstiges = () => {
		let sum = 0;
		this.state.invoice.sonstiges.forEach(element => {
			if (element.active) {
				sum += element.price;
			}
		});
		this.setState({
			sonstigesSum: sum
		});
	};
	setPrintPageSettings = () => {
		let style = document.getElementsByClassName("print-page-style");
		if (style.length > 0) {
			const elem = document.querySelector(".print-page-style");
			elem.parentNode.removeChild(elem);
		}
		style = document.createElement("style");
		style.setAttribute("class", "print-page-style");
		style.innerHTML = `
				@page {
					size: A4;
					margin: 5mm;
				}
				html,body {
					background:white !important;
				}
		`;
		const ref = document.querySelector("script");
		ref.parentNode.insertBefore(style, ref);
	};

	getBookedRooms = () => {
		const bookedRooms = [];
		this.state.invoice.rooms.forEach(room => {
			let type = undefined;
			if (room.ez === true) {
				type = "ez";
			} else if (room.dz === true) {
				type = "dz";
			}
			if (type) {
				bookedRooms.push(room.name + "(" + type + ")");
			}
		});
		this.setState({
			bookedRooms
		});
	};
	componentDidMount() {
		this.setPrintPageSettings();
		this.setupBeforeUnloadListener();
		const cachedHits = localStorage.getItem("bookingInvoice");

		if (cachedHits) {
			this.setState({ invoice: JSON.parse(cachedHits) }, () => {
				if (this.state.invoice.dateArrival && this.state.invoice.dateDeparture) {
					this.getPrices();
					this.getBookedRooms();
					this.calculateRestaurantInvoices();
					this.calculateSonstiges();
				} else {
					this.exit("Es wurde kein Zeitraum angegeben");
				}
			});
		} else {
			this.exit("Datencontainer konnte nicht gelesen werden.");
		}
	}
	print = () => {
		window.print();
	};

	formatPrice = price => {
		return String(price.toFixed(2)).replace(".", ",") + " €";
	};

	render() {
		return (
			<div className="roomInvoice">
				<div className="container">
					<div className="logo-container">
						<div className="logo" />
					</div>
					<div className="main-content">
						<div className="left">
							<h1>Rechnung</h1>
							<div className="basicInfo">
								<h2>Anschrift</h2>
								<ul contentEditable={true} suppressContentEditableWarning={true}>
									<li>Gästehaus am Schlossberg</li>
									<li>Dorothee Bußhoff</li>
									<li>Burgstraße 18 & 20</li>
									<li>54470 Bernkastel - Kues</li>
									<li>info@rotisserie-royale.de</li>
									<li>www.rotisserie-royale.de</li>
								</ul>
							</div>
							<div className="date">
								<h2>Datum</h2>
								<p>{new Date().toLocaleDateString("de-DE")}</p>
							</div>

							<div className="rooms">
								<h2>{this.state.rooms && this.state.rooms.map(room => <li key={room.name}>{room.name}</li>)}</h2>
							</div>
						</div>

						<div className="right">
							<div>
								<div className="header">
									<div className="dates">
										<div className="head">Reisezeitraum</div>
										<div className="content">
											{dayjs(this.state.invoice.dateArrival).format("DD.MM.YYYY")} -{" "}
											{dayjs(this.state.invoice.dateDeparture).format("DD.MM.YYYY")}
										</div>
									</div>
									{this.state.invoice.invoiceNumber && (
										<div className="invoice-num">
											<div className="head">Rechnungsnummer</div>
											<div className="content">RN-{this.state.invoiceNum}</div>
										</div>
									)}
								</div>

								<table>
									<thead>
										<tr>
											<th />
											<th>Anzahl</th>
											<th>Preis</th>
											<th>Gesamtpreis</th>
										</tr>
									</thead>
									<tbody>
										{this.state.invoice.days - this.state.arrangementDays > 0 && (
											<tr className="rooms">
												<td className="room-names">
													<h3>Zimmer:</h3>
													<ul>
														{this.state.rooms &&
															this.state.rooms.map(room => (
																<li key={room.name}>
																	{room.name} ({room.type})
																</li>
															))}
													</ul>
												</td>
												<td className="room-num">
													<ul>
														{this.state.rooms &&
															this.state.rooms.map(room => (
																<li
																	contentEditable={true}
																	suppressContentEditableWarning={true}
																	key={room.name}>
																	{this.state.invoice.days - this.state.arrangementDays}
																</li>
															))}
													</ul>
												</td>

												<td className="room-price">
													<ul>
														{this.state.rooms &&
															this.state.rooms.map(room => (
																<li
																	contentEditable={true}
																	suppressContentEditableWarning={true}
																	key={room.name}>
																	{this.formatPrice(room.price)}
																</li>
															))}
													</ul>
												</td>
												<td className="room-totalPrice">
													<ul>
														{this.state.rooms &&
															this.state.rooms.map(room => (
																<li
																	contentEditable={true}
																	suppressContentEditableWarning={true}
																	key={room.name}>
																	{this.formatPrice(room.roomTotal)}
																</li>
															))}
													</ul>
												</td>
											</tr>
										)}

										{this.state.arrangementDays > 0 && (
											<tr className="rooms">
												<td className="room-names">
													<h3>Arrangement:</h3>
													<ul>
														<li>{this.state.selectedArrangement.name}</li>
													</ul>
												</td>
												<td className="room-num">
													<ul>
														<li>1</li>
													</ul>
												</td>

												<td className="room-price">
													<ul>
														<li>{this.formatPrice(this.state.arrangementNetto)}</li>
													</ul>
												</td>
												<td className="room-totalPrice">
													<ul>
														<li>{this.formatPrice(this.state.arrangementNetto)}</li>
													</ul>
												</td>
											</tr>
										)}

										{this.state.externalBreakfastNum > 0 && (
											<tr>
												<td>
													<h3>Zusätzliches Frühstück</h3>
												</td>
												<td>{this.state.externalBreakfastNum}</td>
												<td>{this.formatPrice(this.state.externalBreakfastPrice)}</td>
												<td>{this.formatPrice(this.state.externalBreakfastSum)}</td>
											</tr>
										)}

										{this.state.invoice.dog === true && (
											<tr>
												<td>
													<h3>Hund</h3>
												</td>
												<td>{this.state.invoice.numdog * this.state.invoice.days}</td>
												<td>{this.formatPrice(this.state.dogPrice / (this.state.taxes.standard + 1))}</td>
												<td>{this.formatPrice(this.state.dogPriceSum)}</td>
											</tr>
										)}

										<tr className="dashed-border">
											<td className="netto">
												<h3>Netto</h3>
												<ul>
													<li>{Math.round(this.state.taxes.hotel * 100)}% Ust.</li>
													<li>{Math.round(this.state.taxes.standard * 100)}% Ust.</li>
												</ul>
											</td>
											<td />
											<td />
											<td contentEditable={true} suppressContentEditableWarning={true}>
												{this.formatPrice(this.state.nettoSum)}
												<ul>
													<li contentEditable={true} suppressContentEditableWarning={true} className="gray">
														{this.formatPrice(this.state.ustSum.hotel)}
													</li>
													<li contentEditable={true} suppressContentEditableWarning={true} className="gray">
														{this.formatPrice(this.state.ustSum.standard)}
													</li>
												</ul>
											</td>
										</tr>
										<tr className="bold-border">
											<td className="brutto">
												<h3>Brutto</h3>
											</td>
											<td />
											<td />
											<td contentEditable={true} suppressContentEditableWarning={true}>
												{this.formatPrice(this.state.bruttoSum)}
											</td>
										</tr>
									</tbody>
								</table>

								{/*


                SONSTIGES



            */}

								<table className="total-table">
									<thead>
										<tr>
											<th />
											<th>Anzahl</th>
											<th>Preis</th>
											<th>Gesamtpreis</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<h3>Gästebeitrag</h3>
												<p className="gray small left-margin">Pro Person und Tag</p>
											</td>
											<td>{String(this.state.invoice.days * this.state.invoice.abgabePerson)}</td>
											<td>{this.formatPrice(this.state.contributionPrice)}</td>
											<td>
												{this.formatPrice(
													this.state.invoice.days * this.state.invoice.abgabePerson * this.state.contributionPrice
												)}
											</td>
										</tr>
										{(this.state.invoice.hp3 || this.state.invoice.hp5) && (
											<tr className="bottom">
												<td>
													<h3>Halbpension</h3>
													<ul>
														{this.state.invoice.hp3 && <li>3 - Gang Menü</li>}
														{this.state.invoice.hp5 && <li>5 - Gang Menü</li>}
													</ul>
												</td>
												<td>
													<ul>
														{this.state.invoice.hp3 && <li>{this.state.invoice.numhp3}</li>}
														{this.state.invoice.hp5 && <li>{this.state.invoice.numhp5}</li>}
													</ul>
												</td>
												<td>
													<ul>
														{this.state.invoice.hp3 && <li>{this.formatPrice(this.state.hp3Price)}</li>}
														{this.state.invoice.hp5 && <li>{this.formatPrice(this.state.hp5Price)}</li>}
													</ul>
												</td>
												<td>
													<ul>
														{this.state.invoice.hp3 && (
															<li>{this.formatPrice(this.state.hp3Price * this.state.invoice.numhp3)}</li>
														)}
														{this.state.invoice.hp5 && (
															<li>{this.formatPrice(this.state.hp5Price * this.state.invoice.numhp5)}</li>
														)}
													</ul>
												</td>
											</tr>
										)}

										{this.state.invoice.restaurantInvoices &&
											this.state.invoice.restaurantInvoices.find(x => x.active === true) && (
												<tr className="bottom">
													<td className="restaurant-invoice">
														<h3>Restaurantrechnung</h3>
														<ul>
															{this.state.invoice.restaurantInvoices &&
																this.state.invoice.restaurantInvoices.map(invoice => {
																	if (invoice.active) {
																		return <li>{invoice.date}</li>;
																	} else {
																		return "";
																	}
																})}
														</ul>
													</td>
													<td />
													<td />
													<td>
														<ul>
															{this.state.invoice.restaurantInvoices &&
																this.state.invoice.restaurantInvoices.map(invoice => {
																	if (invoice.active) {
																		return <li>{this.formatPrice(invoice.price)}</li>;
																	} else {
																		return "";
																	}
																})}
														</ul>
													</td>
												</tr>
											)}

										{this.state.invoice.sonstiges && this.state.invoice.sonstiges.find(x => x.active === true) && (
											<tr className="bottom">
												<td className="restaurant-invoice">
													<h3>Weiteres</h3>
													<ul>
														{this.state.invoice.sonstiges &&
															this.state.invoice.sonstiges.map(sonst => {
																if (sonst.active) {
																	return <li>{sonst.name}</li>;
																} else {
																	return "";
																}
															})}
													</ul>
												</td>
												<td />
												<td />
												<td>
													<ul>
														{this.state.invoice.sonstiges &&
															this.state.invoice.sonstiges.map(sonst => {
																if (sonst.active) {
																	return <li>{this.formatPrice(sonst.price)}</li>;
																} else {
																	return "";
																}
															})}
													</ul>
												</td>
											</tr>
										)}

										<tr className="dotted-border">
											<td className="brutto">
												<h3>Gesamtsumme</h3>
											</td>
											<td />
											<td />
											<td className="totalSum" contentEditable={true} suppressContentEditableWarning={true}>
												{this.formatPrice(this.state.totalSum)}
											</td>
										</tr>
									</tbody>
								</table>

								<div className="invoice-text">
									<p>
										<span className="firstcharacter">V</span>ielen Dank für Ihren Aufenthalt bei uns. Wir freuen uns,
										wenn wir Sie in Zukunft wieder begrüßen dürfen. <br />
										Wir wünschen Ihnen eine schöne Heim- oder Weiterreise.{" "}
									</p>
									<p className="paragraph">Ihr Team des Gästehaus am Schlossberg.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer">
					<p>Umsatzsteuernummer: 43/024/60456</p>
					<p>ID - Nr: DE208945781</p>
					<p>Angaben zum Nachweis der Höhe un der betrieblichen Veranlassung von Bewirtungsaufwendungen ( §4 (V) Nr 2 EstG. )</p>
				</div>
				<div className="paper-watermark" />
			</div>
		);
	}
}

export default PrintRoomInvoice;
