import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import RoomPrices from "../../RoomPrices/RoomPrices";
import DashboardNavigation from "../DashboardNavigation/DashboardNavigation";

import "./Dashboard.scss";
import Home from "../Home/Home";
import Profile from "../Profile/Profile";
import CardDesigner from "../../CardDesigner/CardDesigner";
import RoomInvoiceMain from "../../RoomInvoice/RoomInvoiceMain";
import TableManager from "../../AdminTools/TableManager/TableManager";
import PublicFacilities from "../../VG/PublicFacilities/PublicFacilities";
import RouterHistory from "../../../Helpers/RouterHistory";
import StartingApplication from "./StartingApplication";
import Departments from "../../VG/Departments/Departments";
import RouteManager from "../../AdminTools/RouteManager/RouteManager";
import ManageData from "../ManageData/ManageData";
import Settings from "../Settings/Settings";
import PublicFacilitiesBookings from "../../VG/PublicFacilityBookings/PublicFacilityBookings";
import AbsenceManager from "../../VG/AbsenceManager/AbsenceManager";
import Dropdown, { DropdownTrigger, DropdownContent } from "react-simple-dropdown";
import "react-simple-dropdown/styles/Dropdown.css";
import Impress from "./Impress";
import Privacy from "./Privacy";
import ManageUsers from "../ManageUsers/ManageUsers";
import Fundsachen from "../../VG/Fundsachen/Fundsachen";
import { OneDashDialog, OneDashSpinner } from "@onedash/tools";
import ManageRoles from "../../AdminTools/ManageRoles/ManageRoles";
import Mediathek from "../../VG/Mediathek/Mediathek";

class Dashboard extends Component {
	impressDialog = React.createRef<OneDashDialog>();
	privacyDialog = React.createRef<OneDashDialog>();

	state = {
		currentPageName: "Dashboard",
		navLoaded: false,
		profile: {} as any,
	};
	navLoaded = () => {
		this.setState({ navLoaded: true });
	};

	componentDidMount = () => {
		window.auth.getProfile((profile: any) => {
			this.setState({
				profile,
			});
		});
	};

	render() {
		const { profile } = this.state;
		return (
			<div className="dashboard-container">
				<DashboardNavigation navLoaded={this.navLoaded} />
				{this.state.navLoaded && (
					<>
						<div className="dashboard-headder">
							<div className="nav-buttons">
								<i onClick={RouterHistory.goBack} className="fas fa-chevron-left" />
								<i onClick={RouterHistory.goForward} className="fas fa-chevron-right" />
							</div>
							<div className="currentPage">{this.state.currentPageName}</div>
							<div className="header-icons">
								<Dropdown>
									<DropdownTrigger>
										<i className="fas fa-ellipsis-h" />
									</DropdownTrigger>
									<DropdownContent>
										<ul>
											<li>
												<Link to="/dashboard/profile">
													<img src={profile.picture} width={50} alt="Profile" />
													<div>
														<p className="profile-name">{profile.name}</p>
														<p className="profile-email">{profile.email}</p>
													</div>
												</Link>
											</li>
											<li onClick={() => this.impressDialog.current && this.impressDialog.current.open()}>
												<p>Impressum</p>
											</li>
											<li onClick={() => this.privacyDialog.current && this.privacyDialog.current.open()}>
												<p>Datenschutzerklärung</p>
											</li>
											<li>
												<a href="https://onedash.atlassian.net/servicedesk/customer/portals">
													<p>Hilfe</p>
												</a>
											</li>
										</ul>
									</DropdownContent>
								</Dropdown>
							</div>
						</div>
						<div className="dashboard-content">
							<OneDashDialog bigPadding closeByX maxWidth={600} ref={this.impressDialog}>
								<Impress />
							</OneDashDialog>
							<OneDashDialog bigPadding closeByX maxWidth={600} ref={this.privacyDialog}>
								<Privacy />
							</OneDashDialog>
							<Switch>
								<Route exact path="/dashboard/home/:appId" component={Home} />
								<Route exact path="/dashboard/tableManager/:appId" component={TableManager} />
								<Route exact path="/dashboard/routes/:appId" component={RouteManager} />
								<Route exact path="/dashboard/manageData/:appId" component={ManageData} />
								<Route exact path="/dashboard/publicFacilitiesTimes/:appId" component={PublicFacilitiesBookings} />
								<Route exact path="/dashboard/publicFacilities/:appId" component={PublicFacilities} />
								<Route exact path="/dashboard/roomPrices/:appId" component={RoomPrices} />
								<Route exact path="/dashboard/roomInvoice/:appId" component={RoomInvoiceMain} />
								<Route path="/dashboard/cardDesigner/:appId" component={CardDesigner} />
								<Route exact path="/dashboard/departments/:appId" component={Departments} />
								<Route exact path="/dashboard/userAbsences/:appId" component={AbsenceManager} />
								<Route exact path="/dashboard/users/:appId" component={ManageUsers} />
								<Route exact path="/dashboard/fundsachen/:appId" component={Fundsachen} />
								<Route exact path="/dashboard/profile" component={Profile} />
								<Route exact path="/dashboard/settings" component={Settings} />
								<Route exact path="/dashboard/roles/:appId" component={ManageRoles} />
								<Route exact path="/dashboard/mediathek/:appId" component={Mediathek} />
								<Route path="/dashboard" component={StartingApplication} />
							</Switch>
						</div>
					</>
				)}
				{!this.state.navLoaded && <OneDashSpinner defaultVisible componentStyle="light" />}
			</div>
		);
	}
}

export default withRouter<any, any>(Dashboard);
