/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from "react";
import Page from "./Page";
import "./Styles/CardDesignerCard.scss";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { languages, cardTypes, pageOptions, cardOptions, translations } from "./values";
import { CardProps, TPage, masterCard, FilledPage, CompleteCard, TItem, TCard, languageIndicator } from "../../Interfaces";
import { PrintSettings, Utils } from "../../Helpers/Utils";
import { BackendInterface } from "../../Helpers/BackendInterface";
import { ZUSATZSTOFFE_LIST } from "./values";
import { OneDashSpinner, OneDashDialog, OneDashForm, OneDashSelect, OneDashInput } from "@onedash/tools";

class CardDesignerCard extends Component<CardProps> {
	state = {
		masterCard: {} as masterCard,
		pages: [] as TPage[],
		filledPages: [] as FilledPage[],
		detailCard: {} as CompleteCard,
		detailPage: {} as FilledPage,
		lang: "de" as "de" | "en" | "fr",
		options: { inhaltsstoffe: false, kennzeichnungen: false } as { inhaltsstoffe: boolean; kennzeichnungen: boolean }
	};
	spinner = React.createRef<OneDashSpinner>();
	detailDialog = React.createRef<OneDashDialog>();
	settingsDialog = React.createRef<OneDashDialog>();
	pageDialog = React.createRef<OneDashDialog>();
	addItemButton = React.createRef<HTMLLIElement>();
	currentDragElement = {} as TItem;

	componentDidUpdate = (lastProps: CardProps) => {
		if (this.props.masterCard.id && this.props.masterCard.id !== lastProps.masterCard.id && this.spinner.current) {
			setTimeout(this.spinner.current.show, 300);

			this.setState(
				{
					masterCard: this.props.masterCard
				},
				this.loadCard
			);
			PrintSettings.setPrintSettings(this.props.masterCard.style);
		}
	};
	editCardDetails = (card: CompleteCard) => {
		if (this.detailDialog.current) this.detailDialog.current.toggleOpen();
		this.setState({
			detailCard: card
		});
	};
	loadPages = async () => {
		const pages = (await BackendInterface.getAsyncDataByUrl("/cardDesigner/page/get/" + this.props.masterCard.id)) as TPage[];
		this.setState({ pages });
		return pages;
	};
	convertCards = (cards: TCard[], callback: Function) => {
		const filledCards = [] as CompleteCard[];
		cards.forEach((card: TCard) => {
			BackendInterface.getDataByUrl("/cardDesigner/item/get/" + card.id, (items: TItem[]) => {
				items.forEach(item => {
					if ((item.prices as any).length === 0) {
						item.prices.push({ amount: "0,0 Ltr.", price: 0, id: Utils.generateGuid() });
					}
				});
				filledCards.push({
					id: card.id,
					items,
					masterCard: card.masterCard,
					name: card.name,
					options: card.options,
					order: card.order,
					type: card.type
				});
				if (filledCards.length === cards.length) {
					callback(filledCards);
					return;
				}
			});
		});
	};

	loadCard = async () => {
		const pages = await this.loadPages();
		const ccards = await BackendInterface.getAsyncDataByUrl("/cardDesigner/card/get/" + this.props.masterCard.id);
		this.convertCards(ccards, (cards: CompleteCard[]) => {
			const filledPages = [] as FilledPage[];
			pages.forEach(page => {
				const filledPage = page as FilledPage;
				if (page.cards) {
					page.cards.forEach(cardId => {
						const card = cards.find(x => x.id === cardId);
						if (card) {
							if (!filledPage.filledCards) filledPage.filledCards = [];
							filledPage.filledCards.push(card);
						}
					});
				}
				filledPages.push(filledPage);
			});
			if (this.spinner.current) this.spinner.current.hide();

			this.setState({
				filledPages
			});
		});
	};
	sortItems = ({ oldIndex, newIndex }: any) => {
		const detailCard = this.state.detailCard;
		let items = detailCard.items;
		items = arrayMove(items, oldIndex, newIndex);
		items.forEach((item, index) => {
			BackendInterface.postData("/cardDesigner/item/update", { id: item.id, order: index });
			item.order = index;
		});
		detailCard.items = items;
		this.setState({
			detailCard
		});
	};
	sortCards = ({ oldIndex, newIndex }: any) => {
		const detailPage = this.state.detailPage;
		let filledCards = detailPage.filledCards;
		let cards = detailPage.cards;
		filledCards = arrayMove(filledCards, oldIndex, newIndex);
		cards = arrayMove(cards, oldIndex, newIndex);
		filledCards.forEach((card, index) => {
			BackendInterface.postData("/cardDesigner/card/update", { id: card.id, order: index });
			card.order = index;
		});
		detailPage.cards = cards;
		detailPage.filledCards = filledCards;
		this.setState({
			detailPage
		});
	};
	sortPages = ({ oldIndex, newIndex }: any) => {
		let filledPages = this.state.filledPages;
		filledPages = arrayMove(filledPages, oldIndex, newIndex);
		let pages = this.state.pages;
		pages = arrayMove(pages, oldIndex, newIndex);
		pages.forEach((page, index) => {
			BackendInterface.postData("/cardDesigner/page/update", { id: page.id, order: index });
		});
		this.setState({
			filledPages,
			pages
		});
	};
	addCardItem = () => {
		const btn = this.addItemButton.current;
		if (btn && !btn.classList.contains("disabled")) {
			btn.classList.add("disabled");
			BackendInterface.postData("/cardDesigner/item/add", { cardId: this.state.detailCard.id }, (newItem: TItem) => {
				if (btn) btn.classList.remove("disabled");
				const detailCard = this.state.detailCard;
				const items = detailCard.items;
				items.push(newItem);
				detailCard.items = items;
				this.setState({ detailCard });
			});
		}
	};
	deleteItem = (e: any) => {
		const id = e.target.parentNode.parentNode.getAttribute("data-id");
		BackendInterface.postData("/cardDesigner/item/delete", { id }, () => {
			const detailCard = this.state.detailCard;
			detailCard.items = detailCard.items.filter(x => x.id !== id);
			this.setState({ detailCard });
		});
	};

	saveCardSettings = (name: string) => {
		const detailCard = this.state.detailCard;
		if (!detailCard.options) detailCard.options = {};
		if (!detailCard.options[name]) detailCard.options[name] = {};
		const value = !detailCard.options[name].enabled;
		detailCard.options[name].enabled = value;
		this.setState({
			detailCard
		});
		BackendInterface.postData("/cardDesigner/card/update", { id: detailCard.id, options: detailCard.options });
	};
	savePageSettings = (name: string) => {
		const detailPage = this.state.detailPage;
		if (!detailPage.options) detailPage.options = {};
		if (!detailPage.options[name]) detailPage.options[name] = {};
		const value = !detailPage.options[name].enabled;
		detailPage.options[name].enabled = value;

		this.setState({
			detailPage
		});
		BackendInterface.postData("/cardDesigner/page/update", { id: detailPage.id, options: detailPage.options });
	};
	nameChange = (e: any) => {
		const detailCard = this.state.detailCard;
		const lang = e.target.getAttribute("data-lang") as languageIndicator;
		detailCard.name[lang] = e.target.value;
		this.setState({ detailCard });
		BackendInterface.postData("/cardDesigner/card/update", { id: detailCard.id, name: detailCard.name });
	};
	pageNameChange = (e: any) => {
		const detailPage = this.state.detailPage;
		const lang = e.target.getAttribute("data-lang") as languageIndicator;
		detailPage.name[lang] = e.target.value;
		this.setState({ detailPage });
		BackendInterface.postData("/cardDesigner/page/update", { id: detailPage.id, name: detailPage.name });
	};
	typeChange = (val: any) => {
		const cType = cardTypes.find(x => x.value === val.type);
		if (!cType) return;
		const newType = cType.value as "menu" | "eat" | "drink";

		const detailCard = this.state.detailCard;
		detailCard.options = {};

		if (this.state.detailCard.type !== newType) {
			detailCard.type = newType;
			BackendInterface.postData("/cardDesigner/card/update", {
				id: this.state.detailCard.id,
				type: newType,
				options: {}
			});
			this.setState({ detailCard });
		}
	};
	public togglePageDialog = () => {
		if (this.pageDialog.current) this.pageDialog.current.toggleOpen();
	};
	public toggleSettingsDialog = () => {
		if (this.settingsDialog.current) this.settingsDialog.current.toggleOpen();
	};
	addPage = () => {
		BackendInterface.postData("/cardDesigner/page/add", { masterCard: this.props.masterCard.id }, (newItem: TPage) => {
			const pages = this.state.filledPages;
			const newPage: FilledPage = {
				...newItem,
				filledCards: []
			};
			pages.push(newPage);
			this.setState({
				filledPages: pages
			});
		});
	};
	addCard = () => {
		BackendInterface.postData("/cardDesigner/card/add", { masterCard: this.props.masterCard.id }, (newCard: TCard) => {
			const pages = this.state.filledPages;
			const currentPage = this.state.detailPage;
			const page = pages.find(x => x.id === currentPage.id);
			if (page) {
				if (!page.cards) {
					page.cards = [];
				}
				if (!page.filledCards) {
					page.filledCards = [];
				}
				page.cards.push(newCard.id);

				page.filledCards.push({
					...newCard,
					items: []
				});
				BackendInterface.postData("/cardDesigner/page/update", { id: page.id, cards: page.cards });
				this.setState({
					filledPages: pages
				});
			}
		});
	};
	selectNewParentPage = (card: CompleteCard, val: any) => {
		const filledPages = this.state.filledPages;
		const page = filledPages.find(page => page.cards.indexOf(card.id) >= 0);
		if (page) {
			const i = page.cards.indexOf(card.id);
			page.cards.splice(i, 1);
			page.filledCards.splice(i, 1);
			const p = filledPages.find(x => x.id === val["parent-page"]);
			if (p) {
				p.cards.push(card.id);
				if (!p.filledCards) {
					p.filledCards = [];
				}
				p.filledCards.push(card);
				BackendInterface.postData("/cardDesigner/page/update", { id: page.id, cards: page.cards });
				BackendInterface.postData("/cardDesigner/page/update", { id: p.id, cards: p.cards });

				this.setState({
					filledPages
				});
			}
		}
	};
	deleteCard = (e: any) => {
		const id = e.target.parentNode.parentNode.getAttribute("data-id");
		BackendInterface.postData("/cardDesigner/card/delete", { id }, () => {
			const detailPage = this.state.detailPage;
			detailPage.filledCards = detailPage.filledCards.filter(x => x.id !== id);
			detailPage.cards = detailPage.cards.filter(x => x !== id);
			this.setState({ detailPage });
		});
	};
	deletePage = (e: any) => {
		const id = e.target.parentNode.parentNode.getAttribute("data-id");

		BackendInterface.postData("/cardDesigner/page/delete", { id }, () => {
			let filledPages = this.state.filledPages;
			filledPages = filledPages.filter(x => x.id !== id);
			this.setState({ filledPages });
		});
	};

	buildClassList = () => {
		let classList = `master-card ${this.props.masterCard.style}`;
		if (this.state.options.inhaltsstoffe) {
			classList += " inhaltsstoffe-print";
		}
		if (this.state.options.kennzeichnungen) {
			classList += " kennzeichnungen-print";
		}
		return classList;
	};

	render() {
		const { detailPage, detailCard } = this.state;

		return (
			<div className={this.buildClassList()}>
				<div className="pages">
					{this.state.filledPages.map((page, index) => (
						<Page
							page={page}
							openDetailDialog={this.editCardDetails}
							key={index}
							completeCards={page.filledCards}
							size={this.props.masterCard.paperSize}
							openPageDialog={this.togglePageDialog}
							lang={this.state.lang}
							index={index}
							options={this.state.options}
						/>
					))}
					{this.state.options.kennzeichnungen && (
						<article
							className="page-zusatzstoffe not-editable"
							data-size={this.props.masterCard.paperSize}
							style={{ zIndex: 15 - this.state.filledPages.length + 1 }}>
							<div className="paper-watermark" />
							<div className="card-list">
								<h1>{translations.zusatzstoffe[this.state.lang]}</h1>
								<ul className="liste-zusatzstoffe">
									{ZUSATZSTOFFE_LIST.map((z, i) => (
										<li key={i}>
											<span className="num">{z.num}</span>
											<span className="text">{z.text[this.state.lang]}</span>
										</li>
									))}
								</ul>
							</div>
						</article>
					)}
				</div>

				<OneDashSpinner componentStyle="dark" ref={this.spinner} />

				<div className="pages-dialog">
					<OneDashDialog ref={this.pageDialog} isOpen={false} closeable={true} dialogStyle="dark">
						<h1>Seiten</h1>
						<div className="pages-dialog-content">
							<div className="page-card-list">
								<div className="page-list">
									<h2>Seitenübersicht</h2>
									<SortableContainerEl useDragHandle onSortEnd={this.sortPages}>
										{this.state.filledPages.map((page, index) => (
											<SortableItemEl
												onClick={() => this.setState({ detailPage: page })}
												key={`item-${index}`}
												index={index}
												value={page}
												className={this.state.detailPage.id === page.id ? "selected" : ""}
												deleteItemFunction={this.deletePage}
											/>
										))}
										<li className="add-item" ref={this.addItemButton} onClick={this.addPage}>
											<i className="fas fa-plus-circle" />
										</li>
									</SortableContainerEl>
								</div>
								<div className="page-card-detail-list">
									<h2>Karten</h2>
									{Object.keys(detailPage).length > 0 && (
										<SortableContainerEl useDragHandle onSortEnd={this.sortCards}>
											{detailPage.filledCards &&
												detailPage.filledCards.map((card, index) => (
													<SortableItemEl
														key={`item-${index}`}
														index={index}
														value={card}
														deleteItemFunction={this.deleteCard}
													/>
												))}
											<li className="add-item" ref={this.addItemButton} onClick={this.addCard}>
												<i className="fas fa-plus-circle" />
											</li>
										</SortableContainerEl>
									)}
								</div>
							</div>

							<div className="page-detail-view">
								<div className="page-options">
									<h2>Seiteneinstellungen</h2>
									<ul>
										{Object.keys(detailPage).length > 0 &&
											pageOptions.map((option, index: number) => (
												<li key={index}>
													<OneDashInput
														type="boolean"
														name={option.value}
														onChange={() => this.savePageSettings(option.value)}
														value={detailPage.options?.[option.value]?.enabled === true ? "1" : "0"}
														label={option.name}
													/>
												</li>
											))}
									</ul>
								</div>
								<div className="page-name-detail-view">
									{Object.keys(detailPage).length > 0 && (
										<>
											<h2>Seitenname</h2>
											<div className="page-name-edit">
												{Object.keys(detailPage.name).map(lang => (
													<div className="detail-dialog-edit-name-container" key={lang}>
														<div className="lang-indicator">{lang}</div>
														<input
															type="text"
															onChange={this.pageNameChange}
															data-lang={lang}
															value={detailPage.name[lang as languageIndicator].replace(/<br\/>/g, "\n")}
														/>
													</div>
												))}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</OneDashDialog>
				</div>

				{/* 
				
				
									Settings
				
				
				
				*/}

				<div className="settings-dialog">
					<OneDashDialog ref={this.settingsDialog} isOpen={false} closeable={true} dialogStyle="dark">
						<h1>Einstellungen</h1>

						<div className="language-selection">
							<p>Anzeigesprache</p>
							<OneDashForm
								onChange={(values: any) => {
									this.setState({ lang: values["language-selection"] });
								}}>
								<OneDashSelect native name="language-selection" value={this.state.lang} options={languages} />
							</OneDashForm>
						</div>

						<div className="settings-list">
							<p>Weitere Einstellungen</p>
							<ul>
								<li>
									<OneDashInput
										type="boolean"
										label="Inhaltsstoffe"
										onChange={() =>
											this.setState({
												options: {
													inhaltsstoffe: !this.state.options.inhaltsstoffe,
													kennzeichnungen: this.state.options.kennzeichnungen
												}
											})
										}
										name="option-inhaltsstoffe"
									/>
								</li>
								<li>
									<OneDashInput
										type="boolean"
										label="Kennzeichnungen"
										onChange={() =>
											this.setState({
												options: {
													inhaltsstoffe: this.state.options.inhaltsstoffe,
													kennzeichnungen: !this.state.options.kennzeichnungen
												}
											})
										}
										name="option-kennzeichnungen"
									/>
								</li>
							</ul>
						</div>
					</OneDashDialog>
				</div>

				{/* 
				
				
									Detail Dialog Card
				
				
				
				*/}
				<div className="detail-dialog">
					<OneDashDialog ref={this.detailDialog} isOpen={false} closeable={true} dialogStyle="dark">
						{detailCard.name && (
							<>
								<h1>{detailCard.name.de}</h1>
								<div className="detail-dialog-content">
									<div>
										<div>
											<h2>Name</h2>
											<div className="detail-dialog-edit-name">
												{Object.keys(detailCard.name).map(lang => (
													<div className="detail-dialog-edit-name-container" key={lang}>
														<div className="lang-indicator">{lang}</div>
														<input
															type="text"
															onChange={this.nameChange}
															data-lang={lang}
															value={detailCard.name[lang as languageIndicator].replace(/<br\/>/g, "\n")}
														/>
													</div>
												))}
											</div>
										</div>
										<div>
											<h2>Typ</h2>
											<div className="detail-dialog-type-selection">
												<OneDashForm onChange={this.typeChange}>
													<OneDashSelect native name="type" value={detailCard.type} options={cardTypes} />
												</OneDashForm>
											</div>
											<h2>Zugehörige Seite</h2>
											<div className="detail-dialog-page-selection">
												<OneDashForm onChange={(val: any) => this.selectNewParentPage(detailCard, val)}>
													<OneDashSelect
														name="parent-page"
														native
														value={
															(this.state.pages.find(page => page.cards.includes(detailCard.id)) as TPage).id
														}
														options={this.state.pages.map(p => {
															return { label: p.name.de, value: p.id };
														})}
													/>
												</OneDashForm>
											</div>
										</div>
									</div>
									<div>
										<div>
											<h2>Items</h2>
											<div className="detail-dialog-item-list">
												<SortableContainerEl onSortEnd={this.sortItems} useDragHandle>
													{detailCard.items.map((item, index) => (
														<SortableItemEl
															deleteItemFunction={this.deleteItem}
															key={`item-${index}`}
															index={index}
															value={item}
														/>
													))}
													<li className="add-item" ref={this.addItemButton} onClick={this.addCardItem}>
														<i className="fas fa-plus-circle" />
													</li>
												</SortableContainerEl>
											</div>
										</div>
										<div>
											<h2>Einstellungen</h2>
											<div className="detail-dialog-settings">
												<ul>
													{(cardTypes.find(x => x.value === detailCard.type) as any).options.map(
														(option: any, index: number) => (
															<li key={index}>
																<OneDashInput
																	name={option}
																	value={
																		detailCard?.options[
																			(cardOptions.find(x => x.value === option) as any).value
																		]?.enabled === true
																			? "1"
																			: "0"
																	}
																	label={(cardOptions.find(x => x.value === option) as any).name}
																	onChange={() =>
																		this.saveCardSettings(
																			(cardOptions.find(x => x.value === option) as any).value
																		)
																	}
																	type="boolean"
																/>
															</li>
														)
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</OneDashDialog>
				</div>
			</div>
		);
	}
}
const SortableItemEl = SortableElement(({ value, deleteItemFunction, onClick, className }: any) => {
	let cl = "sortableListItem ";
	if (className) cl += className + " ";
	if (onClick) cl += "clickable ";
	return (
		<li data-id={value.id} className={cl} onClick={onClick && onClick}>
			<DragHandle />
			{value.name.de.split("<br/>")[0]}
			{deleteItemFunction && (
				<button onClick={deleteItemFunction} className="deleteItem">
					<i className="fas fa-minus-circle" />
				</button>
			)}
		</li>
	);
});
const DragHandle = SortableHandle(() => <i className="fas fa-grip-lines" />);
const SortableContainerEl = SortableContainer(({ children }: any) => {
	return <ul>{children}</ul>;
});

export default CardDesignerCard;
