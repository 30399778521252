import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { OneDashCard, OneDashTable } from "@onedash/tools";

export interface RouteManagerProps {}

class RouteManager extends Component<RouteManagerProps> {
	state = {
		routeValues: [] as any[],
		routeHeaders: [] as any[]
	};

	componentDidMount() {
		BackendInterface.getDataByUrl(
			"/base/routes",
			(data: { tableHeaders: any[]; tableData: any[] }) => {
				this.setState({ routeHeaders: data.tableHeaders, routeValues: data.tableData }, Utils.contentIsLoaded);
			},
			false
		);
	}

	saveRoute = (values: any, tableValues: any[]) => {
		this.setState({ routeValues: tableValues });
		const entry = values;
		BackendInterface.postData(
			"/base/routes/save",
			entry,
			(response: any) => {
				if (response.type === "E") {
					const values = this.state.routeValues;
					values.pop();
					this.setState({ routeValues: values });
				}
			},
			false
		);
	};

	deleteRoute = (id: number) => {
		BackendInterface.postData("/base/routes/delete", { id }, (response: any) => {}, false);
	};
	render() {
		return (
			<DashboardContent align="center" cardDisplayType="table">
				<OneDashCard title="Routen" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.routeHeaders}
						onSave={this.saveRoute}
						onDelete={this.deleteRoute}
						tableValues={this.state.routeValues}></OneDashTable>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default RouteManager;
