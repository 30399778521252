import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { OneDashCard, OneDashTable } from "@onedash/tools";
import { Utils } from "../../../Helpers/Utils";
import { BackendInterface } from "../../../Helpers/BackendInterface";
export interface ManageRolesProps {}

export default class ManageRoles extends Component<ManageRolesProps, any> {
	state = { headers: [], values: [] };
	componentDidMount() {
		this.reload();
	}

	reload = () => {
		Utils.contentIsLoading();
		BackendInterface.getDataByUrl("/adminTools/getRolesTable", (data: any) => {
			this.setState({ headers: data.tableHeaders, values: data.tableData }, Utils.contentIsLoaded);
		});
	};

	onSave = (values: any) => {
		BackendInterface.postData("/adminTools/saveRole", values);
	};

	onDelete = (id: any, values: any) => {
		console.log(values);
		BackendInterface.postData("/adminTools/deleteRole/" + values.authId, {});
	};

	render() {
		return (
			<DashboardContent>
				<OneDashCard tableCard>
					<OneDashTable
						onDelete={this.onDelete}
						onSave={this.onSave}
						editable
						tableHeaders={this.state.headers}
						tableValues={this.state.values}></OneDashTable>
				</OneDashCard>
			</DashboardContent>
		);
	}
}
