import React, { Component } from "react";
import updateInputs from "../BasicInput/inputHelper";
import BasicInput from "../BasicInput/BasicInput";
import { BackendInterface } from "../../Helpers/BackendInterface";
import { showNotification } from "../../Helpers/Notify";
import { Utils } from "../../Helpers/Utils";
import DashboardContent from "../Allgemein/Dashboard/DashboardContent";
import Cleave from "cleave.js/react";
import "./RoomInvoice.scss";
import CardWrapper from "../Allgemein/Dashboard/CardWrapper";
import { OneDashCard, OneDashSelect } from "@onedash/tools";

interface RoomInvoiceProps {
	loadingFinishedCallback: Function;
}

class RoomInvoice extends Component<RoomInvoiceProps> {
	state = {
		numChildren: 0,
		numAdult: 0,
		maxPersons: 0,
		hp3: false,
		days: 0,
		hp5: false,
		arrangements: [],
		restaurantInvoices: [{ date: "", price: 0, id: Utils.generateGuid() }],
		sonstiges: [{ name: "", price: 0 }],
		rooms: []
	};
	componentDidUpdate = () => {
		updateInputs();
	};
	componentDidMount = () => {
		this.getRooms();
		this.setupTabGetsFocusBack();
		this.getArrangements();
	};
	async getArrangements() {
		const arrangements = await BackendInterface.getAsyncDataByUrl("/arrangements/get");
		arrangements.forEach((element: any) => {
			element.value = element.id;
			element.label = element.name;
		});
		arrangements.unshift({
			value: "none",
			label: "Kein Sonderangebot",
			id: "0000-0000",
			selected: true,
			days: 0,
			defaultValue: true
		});

		this.setState({
			arrangements
		});
	}
	arrangementChange = (selectedOption: any) => {
		const arrangements = this.state.arrangements;
		arrangements.forEach((element: any) => {
			element.selected = false;
		});
		const selectedObj = arrangements.find((x: any) => x.value === selectedOption) as any;
		if (selectedObj) {
			setTimeout(function() {
				const el = document.querySelector("div[data-select-placeholder='true']");
				if (el) el.innerHTML = selectedObj.label;
			}, 0);
			selectedObj.selected = true;
			this.setState({
				arrangements
			});
		}
	};
	async getRooms() {
		try {
			const rooms = await BackendInterface.getAsyncDataByUrl("/rooms/get");
			this.setState(
				{
					rooms
				},
				() => {
					this.props.loadingFinishedCallback();
				}
			);
		} catch (ex) {}
	}
	public saveInvoice = () => {
		localStorage.setItem("bookingInvoice", JSON.stringify(this.state));
	};

	updateRestaurantInput = (e: any, restaurantInvoice: any) => {
		const invoices = this.state.restaurantInvoices;

		const obj = invoices.find(x => x.id === restaurantInvoice.id) as any;
		if (e.target.name === "price") {
			obj[e.target.name] = Number(e.target.value.replace(",", "."));
		} else {
			obj[e.target.name] = e.target.value;
		}
		const empty = invoices.find(x => x.date === "" && x.price === 0);

		if (obj.date !== "" && obj.price > 0 && !empty) {
			obj.active = true;

			const newObj = { date: "", price: 0, id: Utils.generateGuid() };
			invoices.push(newObj);
		}

		this.setState({
			restaurantInvoices: invoices
		});
	};

	updateSonstInput = (e: any, sonst: any) => {
		const sonstiges = this.state.sonstiges as any;
		const obj = sonstiges.find((x: any) => x.name === sonst.name);
		if (e.target.name === "price") {
			obj[e.target.name] = Number(e.target.value.replace(",", "."));
		} else {
			obj[e.target.name] = e.target.value;
		}

		if (obj.name !== "" && obj.price !== 0) {
			obj.active = true;

			const newObj = { date: "", price: 0 };
			sonstiges.push(newObj);
		}
		this.setState({
			sonstiges
		});
	};

	setupTabGetsFocusBack = () => {
		window.addEventListener("focus", ev => {
			const err = localStorage.getItem("error");
			if (err) {
				const not = {
					type: "E",
					message: err
				} as any;
				showNotification(not);
			}
			localStorage.removeItem("error");
		});
	};

	calculateMaxPersons = () => {
		let num = 0;
		(this.state as any).rooms.forEach((room: any) => {
			if (room.dz === true) {
				num += 2;
			} else if (room.ez === true) {
				num++;
			}
		});
		num = num - this.state.numChildren;
		this.setState({ maxPersons: num, abgabePerson: num, numAdult: num });
	};
	updateInput = (e: any, callback: any) => {
		this.setState({ [e.target.name]: e.target.value }, callback);
		if (e.target.name === "numAdult") {
			this.setState({
				abgabePerson: e.target.value
			});
		}
	};
	updateDateInput = (arrivalDate: any, departureDate: any) => {
		if (arrivalDate && departureDate) {
			const diff = departureDate.diff(arrivalDate, "days");
			this.setState({
				days: diff,
				numhp3: diff * this.state.maxPersons,
				numhp5: diff * this.state.maxPersons,
				dateArrival: arrivalDate.format("l"),
				dateDeparture: departureDate.format("l")
			});
		}
	};
	updateRadio = (e: any, name: any) => {
		const val = !(this.state as any)[name];

		if (val === true) {
			if (!(this.state as any)["num" + name] === true) {
				this.setState({
					["num" + name]: 1
				});
			}
		} else {
			this.setState({
				["num" + name]: 0
			});
		}
		this.setState({
			[name]: val
		});
	};
	date_diff_indays = (date1: any, date2: any) => {
		const dt1 = new Date(date1);
		const dt2 = new Date(date2);
		return Math.floor(
			(Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
				(1000 * 60 * 60 * 24)
		);
	};
	parseDate = (input: any) => {
		const parts = input.match(/(\d+)/g);
		// note parts[1]-1
		return new Date(parts[2], parts[1] - 1, parts[0]);
	};

	selectRoom = (e: any, room: any, type: any) => {
		if (type === "dz") {
			room.ez = false;
		} else {
			room.dz = false;
		}
		room[type] = !room[type];
		this.setState(
			{
				rooms: (this.state as any).rooms
			},
			() => {
				this.calculateMaxPersons();
			}
		);
	};

	render() {
		return (
			<DashboardContent align="center" cardDisplayType="formular" className="room-invoice">
				<CardWrapper title="Allgemein">
					<div>
						<OneDashCard title="Zimmerauswahl" className="room-selection">
							{this.state.rooms &&
								this.state.rooms.map((room: any, index) => (
									<div className="room" key={room.name}>
										<div className="room-name">{room.name}</div>
										<div className="room-types">
											<div
												className={room.dz === true ? "room-selector active" : "room-selector"}
												onClick={e => this.selectRoom(e, room, "dz")}>
												<p>Doppelzimmer</p>
											</div>
											<div
												className={room.ez === true ? "room-selector active" : "room-selector"}
												onClick={e => this.selectRoom(e, room, "ez")}>
												<p>Einzelzimmer</p>
											</div>
										</div>
									</div>
								))}
						</OneDashCard>
					</div>
					<div>
						<OneDashCard title="Zeitraum festlegen">
							<BasicInput type="date-range" callback={this.updateDateInput} />
						</OneDashCard>

						<OneDashCard title="Gästeanzahl">
							<div className="form-horizontal">
								<div className="form-group focused">
									<label className="form-label" htmlFor="adults">
										Erwachsene
									</label>
									<Cleave
										id="adults"
										className="form-input"
										value={this.state.numAdult}
										onBlur={e => this.updateInput(e, undefined)}
										name="numAdult"
										options={{
											numericOnly: true,
											blocks: [2]
										}}
									/>
								</div>
								<div className="form-group focused">
									<label className="form-label" htmlFor="children">
										Kinder
									</label>
									<Cleave
										id="children"
										className="form-input"
										value={this.state.numChildren}
										onBlur={e => this.updateInput(e, undefined)}
										name="numChildren"
										options={{
											numericOnly: true,
											blocks: [2]
										}}
									/>
								</div>
							</div>
						</OneDashCard>
					</div>
				</CardWrapper>

				<CardWrapper className="settings" title="Erweitert">
					<div>
						<OneDashCard title="Gästebeitrag">
							<div className="form-group focused">
								<label className="form-label" htmlFor="abgabePerson">
									Gästeanzahl
								</label>
								<Cleave
									id="abgabePerson"
									className="form-input"
									value={this.state.numAdult}
									onBlur={e => this.updateInput(e, undefined)}
									name="abgabePerson"
									options={{
										numericOnly: true,
										blocks: [2]
									}}
								/>
							</div>
						</OneDashCard>
						<OneDashCard title="Arrangement">
							{this.state.arrangements && (
								<OneDashSelect
									placeholder="Arrangement"
									name="arrangement"
									onChange={this.arrangementChange}
									options={this.state.arrangements}
								/>
							)}
						</OneDashCard>
					</div>
					<div>
						<OneDashCard title="Restaurantrechnung">
							{this.state.restaurantInvoices.map((rest, index) => (
								<div key={index} className="input_container">
									<div className="input form-group">
										<label className="form-label" htmlFor={"restDate-" + index}>
											Datum
										</label>
										<Cleave
											className="date-range form-input"
											value={rest.date}
											id={"restDate-" + index}
											name="date"
											onBlur={e => this.updateRestaurantInput(e, rest)}
											options={{
												date: true,
												datePattern: ["d", "m", "Y"],
												delimiter: "."
											}}
										/>
									</div>
									<div className="input form-group focused">
										<label className="form-label" htmlFor={"restRechnung-" + index}>
											Rechnung
										</label>
										<Cleave
											id={"restRechnung-" + index}
											name="price"
											className="form-input restPrice"
											onBlur={e => this.updateRestaurantInput(e, rest)}
											value={rest.price.toFixed(2).replace(".", ",")}
											options={{
												numeral: true,
												numeralDecimalMark: ","
											}}
										/>
									</div>
								</div>
							))}
						</OneDashCard>

						<OneDashCard title="Weitere Rechnungen">
							{this.state.sonstiges.map((sonst, index) => (
								<div key={index} className="input_container">
									<div className="input form-group">
										<label className="form-label" htmlFor={"additionalsDesc-" + index}>
											Beschreibung
										</label>
										<input
											type="text"
											id={"additionalsDesc-" + index}
											className="form-input"
											name="name"
											onBlur={e => this.updateSonstInput(e, sonst)}
										/>
									</div>
									<div className="input form-group focused">
										<label className="form-label" htmlFor={"additionalsPrice-" + index}>
											Preis
										</label>
										<Cleave
											id={"additionalsPrice-" + index}
											className="form-input price"
											name="price"
											onBlur={e => this.updateSonstInput(e, sonst)}
											value={sonst.price.toFixed(2).replace(".", ",")}
											options={{
												numeral: true,
												numeralDecimalMark: ","
											}}
										/>
									</div>
								</div>
							))}
						</OneDashCard>
					</div>
					<div>
						<OneDashCard title="Sonstiges">
							<div className="form additional-data">
								<div className="input_container">
									<div className="input">
										<div className="onoffswitch">
											<input
												type="checkbox"
												name="hp3"
												onChange={e => this.updateRadio(e, "hp3")}
												className="onoffswitch-checkbox"
												id="hp-3"
												defaultChecked={false}
											/>
											<label className="onoffswitch-label" htmlFor="hp-3">
												HP 3 Gang
											</label>
										</div>
									</div>

									{this.state.hp3 === true && (
										<div className="input form-group focused">
											<label className="form-label" htmlFor="num-hp3">
												Anzahl
											</label>
											<Cleave
												id="num-hp3"
												className="form-input"
												value={(this.state as any).numhp3}
												onBlur={e => this.updateInput(e, undefined)}
												name="numhp3"
												options={{
													numericOnly: true,
													blocks: [2]
												}}
											/>
										</div>
									)}
								</div>

								<div className="input_container">
									<div className="input">
										<div className="onoffswitch">
											<input
												type="checkbox"
												name="hp5"
												onChange={e => this.updateRadio(e, "hp5")}
												className="onoffswitch-checkbox"
												id="hp-5"
												defaultChecked={false}
											/>
											<label className="onoffswitch-label" htmlFor="hp-5">
												HP 5 Gang
											</label>
										</div>
									</div>
									{this.state.hp5 === true && (
										<div className="input form-group focused">
											<label className="form-label" htmlFor="num-hp3">
												Anzahl
											</label>
											<Cleave
												id="num-hp5"
												className="form-input"
												value={(this.state as any).numhp5}
												onBlur={e => this.updateInput(e, undefined)}
												name="numhp5"
												options={{
													numericOnly: true,
													blocks: [2]
												}}
											/>
										</div>
									)}
								</div>

								<div className="input_container">
									<div className="input">
										<div className="onoffswitch">
											<input
												type="checkbox"
												name="invoiceNumber"
												onChange={e => this.updateRadio(e, "invoiceNumber")}
												className="onoffswitch-checkbox"
												id="invoiceNumber"
												defaultChecked={false}
											/>
											<label className="onoffswitch-label" htmlFor="invoiceNumber">
												R - Nummer
											</label>
										</div>
									</div>
								</div>

								<div className="input_container">
									<div className="input">
										<div className="onoffswitch">
											<input
												type="checkbox"
												name="invoiceNumber"
												onChange={e => this.updateRadio(e, "alreadyPaid")}
												className="onoffswitch-checkbox"
												id="alreadyPaid"
												defaultChecked={false}
											/>
											<label className="onoffswitch-label" htmlFor="alreadyPaid">
												Rechnung bezahlt
											</label>
										</div>
									</div>
								</div>

								<div className="input_container">
									<div className="input">
										<div className="onoffswitch">
											<input
												type="checkbox"
												name="dog"
												onChange={e => this.updateRadio(e, "dog")}
												className="onoffswitch-checkbox"
												id="dog"
												defaultChecked={false}
											/>
											<label className="onoffswitch-label" htmlFor="dog">
												Hund
											</label>
										</div>
									</div>
									{(this.state as any).dog === true && (
										<div className="input form-group focused">
											<label className="form-label" htmlFor="numdog">
												Anzahl
											</label>
											<Cleave
												id="numdog"
												className="form-input"
												value={(this.state as any).numdog}
												onBlur={e => this.updateInput(e, undefined)}
												name="numdog"
												options={{
													numericOnly: true,
													blocks: [2]
												}}
											/>
										</div>
									)}
								</div>
							</div>
						</OneDashCard>
					</div>
				</CardWrapper>
			</DashboardContent>
		);
	}
}

export default RoomInvoice;
