import * as React from "react";

export interface PrivacyProps {}

const Privacy: React.SFC<PrivacyProps> = () => {
	return (
		<div className="privacy privacy-impress">
			<h1>Datenschutz</h1>
			<h2>Verantwortlich im Sinne der Datenschutzgesetzes:</h2>
			<p>
				OneDash.
				<br />
				Burgstraße 19
				<br />
				54470 Bernkastel - Kues
				<br />
			</p>
			<h2>Erfassung von Daten</h2>
			<p>
				Während Sie auf unsere Webseiten zugreifen, erfassen wir automatisch Daten von allgemeiner Natur. Diese Daten (Server-Logfiles) umfassen zum Beispiel die Art
				ihres Browsers, ihr Betriebssystem, den Domainnamen Ihres Internetanbieters sowie weitere ähnliche allgemeine Daten. Diese Daten sind absolut Personen
				unabhängig und werden genutzt, um Ihnen die Webseiten korrekt darzustellen und werden bei jeder Nutzung des Internets abgerufen. Die absolut anonymen Daten
				werden statistisch ausgewertet um unseren Service für Sie zu verbessern.
			</p>

			<h2>Anmeldung auf unserer Webseite</h2>
			<p>
				Bei der Anmeldung für spezifische Angebote erfragen wir einige persönliche Daten wie Name, Anschrift, Kontakt, Telefonnummer oder E-Mail-Adresse. Angemeldete
				Nutzer können auf bestimmte Zusatzleistungen zugreifen. Angemeldete Nutzer haben die Möglichkeit, alle angegebenen persönlichen Daten zu jedem Zeitpunkt zu
				ändern oder löschen zu lassen. Sie können auch jederzeit die von ihnen gespeicherten Daten bei uns erfragen. Soweit gesetzlich keine Frist für die Aufbewahrung
				der Daten besteht, können diese geändert oder gelöscht werden. Bitte kontaktieren Sie uns dazu über unsere Kontaktseite.
			</p>

			<h2>Kontaktformular</h2>
			<p>Wenn Sie uns über unsere Kontaktseite kontaktieren oder uns eine E-Mail schicken werden die entsprechenden Daten zur Bearbeitung gespeichert.</p>
			<h2>Löschung oder Sperrung von Daten</h2>
			<p>
				Ihre persönlichen Daten werden nur so lange gespeichert, wie dies absolut notwendig ist um die angegebenen Dienste zu leisten und es vom Gesetz vorgeschrieben
				ist. Nach Ablauf dieser Fristen werden die persönlichen Daten der Nutzer regelmäßig gesperrt oder gelöscht.
			</p>

			<h2>Cookies</h2>
			<p>
				Unsere Webseite verwendet „Cookies“. Cookies sind Textdateien, die vom Server einer Webseite auf Ihren Rechner übertragen werden. Bestimmte Daten wie
				IP-Adresse, Browser, Betriebssystem und Internet Verbindung werden dabei übertragen. Cookies starten keine Programme und übertragen keine Viren. Die durch
				Cookies gesammelten Informationen dienen dazu, Ihnen die Navigation zu erleichtern und die Anzeige unserer Webseiten zu optimieren. Daten, die von uns erfasst
				werden, werden niemals ohne Ihre Einwilligung an Daten an Dritte weitergegeben oder mit personenbezogenen Daten verknüpft. Die Verwendung von Cookies kann über
				Einstellungen in ihrem Browser verhindert werden. In den Erläuterungen zu Ihrem Internetbrowsers finden Sie Informationen darüber, wie man diese Einstellungen
				verändern kann. Einzelne Funktionen unserer Website können unter Umständen nicht richtig funktionieren, wenn die Verwendung von Cookies desaktiviert ist.
			</p>

			<h2>Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h2>
			<p>
				Sie können zu jedem Zeitpunkt Informationen über Ihre bei uns gespeicherten Daten erbitten. Diese können auch berichtigt, gesperrt oder, sofern die
				vorgeschriebene Zeiträume der Datenspeicherung zur Geschäftsabwicklung abgelaufen sind, gelöscht werden. Unser Datenschutzmitarbeiter hilft Ihnen bei allen
				entsprechenden Fragen. Seine Kontaktdaten finden Sie weiter unten. Gesperrte Daten werden in einer speziellen Sperrdatei zu Kontrollzwecken verwahrt. Wenn die
				gesetzliche Archivierungsverpflichtung abgelaufen ist können Sie auch die Löschung der Daten verlangen. Innerhalb der Archivierungsverpflichtung können wir
				Ihre Daten auf Wunsch sperren. Änderungen oder Widerruf von Einwilligungen können durch eine Mitteilung an uns vorgenommen werden. Dies ist auch für zukünftige
				Aktionen möglich.
			</p>

			<h2>Änderung der Datenschutzbestimmungen</h2>
			<p>
				Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um
				Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue
				Datenschutzerklärung.
			</p>
			<h2>Kontakt zum Datenschutzmitarbeiter</h2>
			<p>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an info@onedash.de mit dem Betreff „Datenschutz".</p>
		</div>
	);
};

export default Privacy;
