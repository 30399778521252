import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../Style/normalize.scss";
import "../../../Style/default.scss";
import { withRouter } from "react-router-dom";
import { createStore } from "redux";
import reducer from "../../../Helpers/Reducer";
import Auth from "../../../Auth/Auth";
import AuthCallback from "../../../Auth/AuthCallback";
import { MAIN_SETTINGS } from "../../../Helpers/Settings";
import Login from "../../Login/Login";
import BackendError from "../BackendError/BackendError";
import "./App.scss";
import "./Collapsible.scss";
import { OneDashDialog } from "@onedash/tools";
import { Utils } from "../../../Helpers/Utils";

window.reduxStore = createStore(reducer);
interface AppState {
	auth: Auth;
	currentPageName: string;
}
class App extends Component<any, AppState> {
	updateDialog = React.createRef<OneDashDialog>();

	constructor(props: any) {
		super(props);
		window.auth = new Auth(this.props.history);
		this.state = {
			auth: window.auth,
			currentPageName: ""
		};
	}

	componentDidMount() {
		const currentPage = this.props.history.location.pathname;
		if (MAIN_SETTINGS.logonFreePages.indexOf(currentPage) === -1) {
			try {
				this.state.auth.silentAuth(err => {
					if (err) {
						if (Utils.isOffline() === false) {
							this.state.auth.redirectToLogin();
						}
					}
				});
			} catch (ex) {}
		}

		const t = MAIN_SETTINGS.allowedSubPages.find(x => {
			const r = new RegExp(x);
			return r.test(currentPage);
		});

		if (!t) {
			if (currentPage.indexOf("dashboard") === -1 && MAIN_SETTINGS.logonFreePages.indexOf(currentPage) === -1) {
				this.props.history.push("/dashboard");
			}
		}

		const store = window.reduxStore;
		store.subscribe(() => {
			const data = window.reduxStore.getState();
			if (data.currentPageName) {
				if (data.currentPageName !== this.state.currentPageName) {
					this.changeTitle(data.currentPageName);
					this.setState({ currentPageName: data.currentPageName });
				}
			}
			if (data.updateAvailable && this.updateDialog.current) {
				this.updateDialog.current.open();
			}
		});
	}
	changeTitle = (currentPageName: string) => {
		const title = document.querySelector("title");
		if (title) {
			title.innerHTML = currentPageName;
		}
	};
	reloadApp = () => {
		window.location.reload(true);
	};

	render() {
		return (
			<div>
				<ToastContainer />

				<OneDashDialog
					className="update-available"
					closeable={false}
					ref={this.updateDialog}
					max
					isOpen={false}
					title="Update verfügbar">
					<p>Die Anwendung benötigt ein Udpate. Bitte laden Sie die Seite neu, um das Update zu installieren.</p>
					<button className="btn btn__positive" onClick={this.reloadApp}>
						Seite neuladen
					</button>
				</OneDashDialog>

				<Switch>
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/backend-error" component={BackendError} />
					<Route path="/callback" render={props => <AuthCallback auth={this.state.auth} {...props} />} />
					<Route path="/login" render={props => <Login auth={this.state.auth} {...props} />} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
