/* eslint-disable @typescript-eslint/camelcase */
import auth0, { WebAuth, Auth0Error } from "auth0-js";
import { Utils } from "../Helpers/Utils";
import jwtDecode from "jwt-decode";
const REDIRECT_ON_LOGIN = "/login-redirect";

// Stored outside class since private
// eslint-disable-next-line
let _idToken = null;
let _accessToken = "";
let _scopes = "";
let _expiresAt = 0;
let _roles = [] as string[];

export default class Auth {
	history: any;
	userProfile: any;
	requestedScopes: string;
	auth0: WebAuth;

	constructor(history: History) {
		this.history = history;
		this.userProfile = null;
		this.requestedScopes = "openid profile email";

		this.auth0 = new auth0.WebAuth({
			domain: process.env.REACT_APP_AUTH0_DOMAIN as any,
			clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as any,
			redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			responseType: "token id_token",
			scope: this.requestedScopes
		});
	}
	silentAuth = (callback: (error_message?: Auth0Error) => any) => {
		this.auth0.checkSession({}, (err, authResult) => {
			if (err) return callback(err);
			this.setSession(authResult);
			callback();
		});
	};

	loginByUsernameAndPassword = (username: string, password: string, callback: (err: Auth0Error | null, result: any) => void) => {
		this.auth0.login(
			{
				username: username,
				password: password,
				realm: "Username-Password-Authentication"
			},
			callback
		);
	};

	resetPassword = (email: string, callback: (isValid: boolean) => void) => {
		this.auth0.changePassword(
			{
				connection: "Username-Password-Authentication",
				email
			},
			(err, result) => {
				if (err) callback(false);
				if (result) callback(true);
			}
		);
	};

	redirectToLogin = () => {
		localStorage.setItem(REDIRECT_ON_LOGIN, JSON.stringify(this.history.location));

		this.history.push("/login");
	};

	handleAuthentication = () => {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
				const redirectUri = localStorage.getItem(REDIRECT_ON_LOGIN);
				const redirectLocation = !redirectUri ? "/dashboard" : JSON.parse(redirectUri);
				this.history.push(redirectLocation);
			} else if (err) {
				this.history.push("/");
				console.error(`Error: ${err.error}. Check the console for further details.`);
				console.error(err);
			}
			localStorage.removeItem(REDIRECT_ON_LOGIN);
		});
	};

	setSession = (authResult: any) => {
		// set the time that the access token will expire
		_expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

		// If there is a value on the `scope` param from the authResult,
		// use it to set scopes in the session for the user. Otherwise
		// use the scopes as requested. If no scopes were requested,
		// set it to nothing
		_scopes = authResult.scope || this.requestedScopes || "";

		const decoded = jwtDecode(authResult.accessToken);
		_roles = (decoded as any)[process.env.REACT_APP_AUTH0_ROLE_DOMAIN as string];

		_accessToken = authResult.accessToken;

		window.reduxStore.dispatch({ type: "ACCESS_TOKEN_LOADED" });
		_idToken = authResult.idToken;
		this.scheduleTokenRenewal();
	};

	isAuthenticated() {
		return new Date().getTime() < _expiresAt;
	}

	logout = () => {
		this.auth0.logout({
			clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
			returnTo: process.env.REACT_APP_LOGIN_URL
		});
	};

	getAccessToken = () => {
		if (!_accessToken) {
			throw new Error("No access token found.");
		}
		return _accessToken;
	};
	getRoles = () => {
		return _roles;
	};

	getProfile = (callback: Function) => {
		if (this.userProfile) return callback(this.userProfile);

		Utils.waitForAccessToken().then(() => {
			this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
				if (profile) this.userProfile = profile;
				callback(profile, err);
			});
		});
	};

	userHasScopes(scopes: string[]) {
		const grantedScopes = (_scopes || "").split(" ");
		return scopes.every(scope => grantedScopes.includes(scope));
	}

	renewToken(callback?: Function) {
		this.auth0.checkSession({}, (err, result) => {
			if (err) {
				console.error(`Error: ${err.error} - ${err.error_description}.`);
			} else {
				this.setSession(result);
			}
			if (callback) callback(err, result);
		});
	}

	scheduleTokenRenewal() {
		const delay = _expiresAt - Date.now();
		if (delay > 0) setTimeout(() => this.renewToken(), delay);
	}
}
