import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { OneDashCard, OneDashTable } from "@onedash/tools";

export interface PublicFacilitiesProps {}

class PublicFacilities extends Component<PublicFacilitiesProps> {
	state = {
		bHaueserTableValues: [] as any[],
		bHaueserTableHeaders: [] as any[],

		schutzTableValues: [] as any[],
		schutzTableHeaders: [] as any[]
	};

	componentDidMount() {
		BackendInterface.getDataByUrl("/publicFacilities/buergerhaeuser", (data: { tableHeaders: any[]; tableData: any[] }) => {
			this.setState({ bHaueserTableHeaders: data.tableHeaders, bHaueserTableValues: data.tableData }, Utils.contentIsLoaded);
		});
		BackendInterface.getDataByUrl("/publicFacilities/schutzhuetten", (data: { tableHeaders: any[]; tableData: any[] }) => {
			this.setState({ schutzTableHeaders: data.tableHeaders, schutzTableValues: data.tableData }, Utils.contentIsLoaded);
		});
	}

	saveBEntry = (values: any, tableValues: any[]) => {
		this.setState({ bHaueserTableValues: tableValues });
		const entry = values;
		BackendInterface.postData("/publicFacilities/buergerhaeuser/save", entry, (response: any) => {
			if (response.type === "E") {
				const values = this.state.bHaueserTableValues;
				values.pop();
				this.setState({ bHaueserTableValues: values });
			}
		});
	};

	deleteBEntry = (id: number) => {
		BackendInterface.postData("/publicFacilities/buergerhaeuser/delete", { id });
	};
	saveEntry = (values: any, tableValues: any[]) => {
		this.setState({ schutzTableValues: tableValues });
		const entry = values;
		BackendInterface.postData("/publicFacilities/schutzhuetten/save", entry, (response: any) => {
			if (response.type === "E") {
				const values = this.state.schutzTableValues;
				values.pop();

				this.setState({ schutzTableValues: values });
			}
		});
	};

	deleteSEntry = (id: number) => {
		BackendInterface.postData("/publicFacilities/schutzhuetten/delete", { id });
	};
	render() {
		return (
			<DashboardContent align="center" cardDisplayType="table">
				<OneDashCard title="Bürgerhäuser" className="table-doubleRow" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.bHaueserTableHeaders}
						onSave={this.saveBEntry}
						onDelete={this.deleteBEntry}
						tableValues={this.state.bHaueserTableValues}></OneDashTable>
				</OneDashCard>
				<OneDashCard title="Grill und Schutzhütten" className="table-doubleRow" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.schutzTableHeaders}
						onSave={this.saveEntry}
						onDelete={this.deleteSEntry}
						tableValues={this.state.schutzTableValues}></OneDashTable>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default PublicFacilities;
