let updateInputs = () => {
	let input = document.querySelectorAll("input");
	input.forEach(element => {
		if (element.value === "") {
			element.classList.remove("filled");
			element.parentNode.classList.remove("live-focused");
		}
		//Focus
		element.addEventListener("focus", e => {
			e.target.parentNode.classList.add("live-focused");
		});

		//Blur
		element.addEventListener("blur", e => {
			let inputVal = e.target.value;
			if (inputVal === "") {
				e.target.classList.remove("filled");
				e.target.parentNode.classList.remove("live-focused");
			} else {
				e.target.classList.add("filled");
			}
		});
	});
};

export default updateInputs;
