/* eslint-disable indent */
import React, { Component } from "react";
import Itemproperties from "./ItemProperties";
import { translations } from "./values";
import { PageProps, TItem, TCard } from "../../Interfaces";
import { BackendInterface } from "../../Helpers/BackendInterface";
import { Utils } from "../../Helpers/Utils";

class Page extends Component<PageProps> {
	state = {
		completeCards: this.props.completeCards,
		visible: true,
		page: {} as any,
		lang: this.props.lang
	};
	componentDidMount() {
		this.setState({ page: this.props.page });
	}
	componentDidUpdate = (props: PageProps) => {
		if (this.props.page !== props.page || this.props.completeCards !== props.completeCards || this.props.lang !== props.lang) {
			this.setState({ page: this.props.page, completeCards: this.props.completeCards, lang: this.props.lang });
		}
	};

	toggleItemProperties = (item: TItem) => {
		const completeCards = this.props.completeCards;
		completeCards.forEach(completeCard => {
			const i = completeCard.items.find(x => x.id === item.id);
			if (i) i.clicked = !i.clicked;
		});
		this.setState({
			completeCards
		});
	};
	toggleCardOptionProperties = (cardId: string, property = "default") => {
		const completeCards = this.props.completeCards;
		const card = completeCards.find(x => x.id === cardId);
		if (card) {
			if (!card.clicked) card.clicked = {};
			card.clicked[property] = !card.clicked[property];
			this.setState({
				completeCards
			});
		}
	};
	itemUpdated = (item: TItem) => {
		const completeCards = this.props.completeCards;
		completeCards.forEach(completeCard => {
			let i = completeCard.items.find(x => x.id === item.id);
			if (i) i = item;
		});
		BackendInterface.postData("/cardDesigner/item/update", {
			id: item.id,
			name: item.name,
			prices: item.prices,
			allergene: item.allergene,
			zusatzstoffe: item.zusatzstoffe
		});
		this.setState({
			completeCards
		});
	};
	priceChange = (e: any, item: TItem) => {
		const price = Number(e.target.innerHTML.replace("€", "").replace(",", "."));
		if (item.prices[0]) {
			item.prices[0].price = price;
		} else {
			item.prices.push({ amount: "1Stk.", price });
		}
		this.itemUpdated(item);
	};
	priceAmountChange = (
		value: string | number,
		type: "amount" | "price",
		item: TItem,
		price: {
			amount: string;
			price: number;
			id?: string;
		}
	) => {
		const pIndex = item.prices.findIndex(x => x?.id === price.id);
		const p = item.prices[pIndex];
		if (p) {
			if (!p.id) {
				p.id = Utils.generateGuid();
			}
			(p as any)[type] = value;

			this.itemUpdated(item);
		}
	};
	formatPrice = (price: number) => {
		if (price && typeof price === "number") {
			return (
				price
					.toFixed(2)
					.toString()
					.replace(".", ",") + "€"
			);
		} else {
			return "0,00€";
		}
	};
	procedureItemName = (name: string) => {
		const str = name.split("<br/>");
		let a = `<span class='first-line'>${str[0]}</span><br/>`;
		str.shift();
		a += str.join("<br/>");
		return a;
	};
	priceToNum = (price: string) => {
		return Number(price.replace("€", "").replace(",", "."));
	};
	cardOptionValueChange = (value: any, optionName: string, id: string) => {
		const cards = this.state.completeCards;
		const card = cards.find(x => x.id === id);
		if (card) {
			card.options[optionName].value = value;
			BackendInterface.postData("/cardDesigner/card/update", { id, options: card.options });
			this.setState({ completeCards: cards });
		}
	};
	getItemClass = (card: TCard) => {
		const classList = ["main-item"];
		if (card.options && card.options["multi-line-items"] && card.options["multi-line-items"].enabled === true) {
			classList.push("border-disabled");
		}
		if (card.options && card.options["item-text"] && card.options["item-text"].enabled === true) {
			classList.push("item-has-description");
		}
		return classList.join(" ");
	};

	render() {
		const { lang } = this.state;
		if (Object.keys(this.state.page).length === 0) return false;
		return (
			<article
				data-size={this.props.size}
				style={{ zIndex: 15 - this.props.index }}
				className={this.state.visible ? "not-editable" : ""}>
				<div
					className="button-corner"
					onClick={() => {
						this.setState({ visible: !this.state.visible });
					}}>
					{this.state.visible ? <i className="far fa-edit" /> : <i className="fas fa-eye" />}
				</div>
				<div className="paper-watermark" />
				{this.state.page.options && this.state.page.options["page-name"] && this.state.page.options["page-name"].enabled === true && (
					<h1 className="page-title clickable-text" onClick={this.props.openPageDialog}>
						{this.state.page.name[lang]}
					</h1>
				)}
				<div
					className={this.state.page?.options?.["centered-content"]?.enabled ? "card-list centered-list" : "card-list grid-list"}>
					{this.state.completeCards &&
						this.state.completeCards.map((card, cardIndex) => (
							<div className={"card " + card.type} key={card.id}>
								<h1
									onClick={() => {
										this.props.openDetailDialog(card);
									}}>
									{card.name[lang]}
								</h1>
								<div className="card-items">
									{card.items.map((item, index) => (
										<div key={item.id}>
											<div className="card-item">
												<div data-card-index={cardIndex} className={this.getItemClass(card)}>
													<div className="item-name-container">
														<span
															className="item-name"
															onClick={() => this.toggleItemProperties(item)}
															dangerouslySetInnerHTML={{ __html: this.procedureItemName(item.name[lang]) }}
														/>
														{this.props.options.kennzeichnungen && (
															<sup className="item-number">
																{item?.zusatzstoffe
																	?.map(z => z.num)
																	?.sort((a, b) => a - b)
																	.join(",")}
															</sup>
														)}
														{!this.state.page?.options?.["no-inhaltsstoffe"]?.enabled &&
															this.props.options.inhaltsstoffe &&
															item?.allergene?.length > 0 && (
																<span className="item-zusatzstoffe">
																	<span className="bold">{translations.contains[lang]} </span>
																	{item?.allergene?.map(z => z[lang])?.join(", ")}
																</span>
															)}
														{item.clicked === true && (
															<Itemproperties
																closeComponent={() => this.toggleItemProperties(item)}
																itemUpdated={this.itemUpdated}
																item={item}
															/>
														)}
													</div>

													{/* 				PRICE					*/}
													{card.options && card.options.prices && card.options.prices.enabled === true && (
														<div className="item-price">
															<div
																contentEditable={true}
																onBlur={e => {
																	this.priceChange(e, item);
																}}
																suppressContentEditableWarning={true}>
																{item.prices[0] && item.prices[0].price
																	? this.formatPrice(item.prices[0].price)
																	: "0,00€"}
															</div>
														</div>
													)}

													{/* 				PRICE per Amount 					*/}

													{card.options && card.options.amount && card.options.amount.enabled === true && (
														<div className="item-amount-price">
															{item.prices.map((price, index) => (
																<div key={index}>
																	{price !== null && (
																		<>
																			<div
																				onBlur={e => {
																					this.priceAmountChange(
																						e.target.innerHTML,
																						"amount",
																						item,
																						price
																					);
																				}}
																				className="item-amount"
																				contentEditable={true}
																				suppressContentEditableWarning>
																				{price.amount}
																			</div>
																			<div
																				className="item-price"
																				onBlur={e => {
																					this.priceAmountChange(
																						this.priceToNum(e.target.innerHTML),
																						"price",
																						item,
																						price
																					);
																				}}
																				contentEditable={true}
																				suppressContentEditableWarning>
																				{this.formatPrice(price.price)}
																			</div>
																			<div className="price-buttons">
																				<i
																					onClick={() => {
																						delete item.prices[index];
																						this.itemUpdated(item);
																					}}
																					className="fas fa-minus-circle"
																				/>
																				<i
																					className="fas fa-plus-circle"
																					onClick={() => {
																						item.prices.push({
																							amount: "0,0 Ltr.",
																							price: 0,
																							id: Utils.generateGuid()
																						});
																						this.itemUpdated(item);
																					}}
																				/>
																			</div>
																		</>
																	)}
																</div>
															))}
														</div>
													)}
												</div>
											</div>
											{index !== card.items.length - 1 && <span className="item-divider">fff</span>}
										</div>
									))}
								</div>
								{/* 	
															MENU Price
								*/}
								{card.options && card.options.price && card.options.price.enabled === true && (
									<div className="menu-price">
										<span>{translations.perPerson[lang]} </span>
										<span
											contentEditable={true}
											onBlur={e => {
												this.cardOptionValueChange(this.priceToNum(e.target.innerHTML), "price", card.id);
											}}
											suppressContentEditableWarning={true}>
											{card.options.price.value ? this.formatPrice(card.options.price.value) : "0,00€"}
										</span>
									</div>
								)}

								{/*
													MENU PERSON NUM
								*/}
								{card.options && card.options["person-num"] && card.options["person-num"].enabled === true && (
									<div className="person-num">
										<span dangerouslySetInnerHTML={{ __html: translations.menuFor[lang] }} />
										<span
											contentEditable={true}
											onBlur={e => {
												this.cardOptionValueChange(Number(e.target.innerHTML), "person-num", card.id);
											}}
											suppressContentEditableWarning={true}>
											{" "}
											{card.options["person-num"].value ? Number(card.options["person-num"].value) : 0}{" "}
										</span>
										<span dangerouslySetInnerHTML={{ __html: translations.persons[lang] }} />
									</div>
								)}
								{/*
													CORRESPONDING WINES
								*/}
								{card.options &&
									card.options["corresponding-wines"] &&
									card.options["corresponding-wines"].enabled === true && (
										<div className="corresponding-wines">
											<p dangerouslySetInnerHTML={{ __html: translations.correspondingWines[lang] }} />
											<span>{translations.perPerson[lang]} </span>
											<span
												contentEditable={true}
												onBlur={e => {
													this.cardOptionValueChange(
														this.priceToNum(e.target.innerHTML),
														"corresponding-wines",
														card.id
													);
												}}
												suppressContentEditableWarning={true}>
												{card.options["corresponding-wines"].value
													? this.formatPrice(card.options["corresponding-wines"].value)
													: "0,00€"}
											</span>
										</div>
									)}

								{/*
													WINE RECOMMENDATION
								*/}

								{card.options &&
									card.options["wine-recommendation"] &&
									card.options["wine-recommendation"].enabled === true && (
										<div className="menu-wine-recommendation">
											<p>{translations.wineRecommendation[lang]} </p>

											<p
												onClick={() => {
													this.toggleCardOptionProperties(card.id, "wine-recommendation");
												}}
												dangerouslySetInnerHTML={{
													__html:
														card.options["wine-recommendation"].value &&
														card.options["wine-recommendation"].value.name &&
														card.options["wine-recommendation"].value.name[lang]
															? card.options["wine-recommendation"].value.name[lang]
															: "Wein"
												}}
											/>

											{card.clicked && card.clicked["wine-recommendation"] && (
												<Itemproperties
													item={card.options["wine-recommendation"].value}
													itemUpdated={(item: any) => {
														this.cardOptionValueChange(item, "wine-recommendation", card.id);
													}}
													closeComponent={() => {
														this.toggleCardOptionProperties(card.id, "wine-recommendation");
													}}
												/>
											)}
										</div>
									)}

								{/*
													TEXT
								*/}
								{card.options && card.options["additionalText"] && card.options["additionalText"].enabled === true && (
									<div className="additional-text">
										<p
											onClick={() => {
												this.toggleCardOptionProperties(card.id, "additionalText");
											}}
											dangerouslySetInnerHTML={{
												__html:
													card.options["additionalText"].value &&
													card.options["additionalText"].value.name &&
													card.options["additionalText"].value.name[lang]
														? card.options["additionalText"].value.name[lang]
														: "Zusätzlicher Text"
											}}
										/>

										{card.clicked && card.clicked["additionalText"] && (
											<Itemproperties
												item={card.options["additionalText"].value}
												itemUpdated={(item: any) => {
													this.cardOptionValueChange(item, "additionalText", card.id);
												}}
												closeComponent={() => {
													this.toggleCardOptionProperties(card.id, "additionalText");
												}}
											/>
										)}
									</div>
								)}
							</div>
						))}
				</div>
			</article>
		);
	}
}

export default Page;
