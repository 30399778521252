/* eslint-disable eqeqeq */
import * as React from "react";
import { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import Collapsible from "react-collapsible";
import { Fundsache } from "../../../Interfaces";
import ImgsViewer from "react-images-viewer";
import "./Fundsachen.scss";
import { OneDashCard, OneDashForm, OneDashInput } from "@onedash/tools";
import { showNotification } from "../../../Helpers/Notify";
import dayjs from "dayjs";
import "dayjs/locale/de"; // load on demand
dayjs.locale("de");

export interface FundsachenProps {}

export interface FundsachenState {}
const fileLocationPath = "/vg/fundbuero/image/";
class Fundsachen extends Component<FundsachenProps, FundsachenState> {
	state = {
		fundsachen: [] as Fundsache[],
		currImg: [] as number[],
		isOpen: [] as boolean[],
		imageRotations: [] as number[][],
		currentIndex: -1 as number
	};
	componentDidMount() {
		BackendInterface.getDataByUrl("/vg/fundbuero/getFundsachen", fundsachen => {
			this.setState({ fundsachen }, Utils.contentIsLoaded);
		});
	}
	openImgsViewer = (collapsibleNum: number, index: number, event: any) => {
		const { isOpen, currImg } = this.state;
		isOpen[collapsibleNum] = true;
		currImg[collapsibleNum] = index;
		event.preventDefault();
		this.setState({ currImg, isOpen, currentIndex: collapsibleNum }, () => {
			setTimeout(this.rotateCurrentImage, 100);
		});
	};
	closeImgsViewer = (collapsibleNum: number) => {
		const { isOpen, currImg } = this.state;
		isOpen[collapsibleNum] = false;
		currImg[collapsibleNum] = 0;
		this.setState({ currImg, isOpen, currentIndex: -1 });
	};
	updateCurrentImage = (collapsibleNum: number, type: "+" | "-") => {
		const { currImg } = this.state;
		if (type === "+") {
			currImg[collapsibleNum]++;
		} else {
			currImg[collapsibleNum]--;
		}
		this.setState({ currImg });
	};

	waitingTrigger = (id: any, location: string, type: string) => {
		return (
			<div className="trigger">
				<div className="title">{`${location}: ${type}`}</div>
				<div className="reactions">
					<button className="btn btn__positive" onClick={() => this.changeStatus(id, undefined, "published")}>
						Veröffentlichen
					</button>
					<button className="btn" onClick={() => this.changeStatus(id, undefined, "declined")}>
						Ablehnen
					</button>
				</div>
			</div>
		);
	};
	publishedTrigger = (id: any, location: string, type: string) => {
		return (
			<div className="trigger">
				<div className="title">{`${location}: ${type}`}</div>
				<div className="reactions">
					<button className="btn btn__positive" onClick={() => this.changeStatus(id, undefined, "closed")}>
						Von der Seite nehmen
					</button>
				</div>
			</div>
		);
	};

	customControls = () => {
		return [<h1>+</h1>, <h1>-</h1>];
	};

	changeStatus = (id: any, showImages: "1" | "0" | undefined, status?: "declined" | "closed" | "published") => {
		const fundsachen = this.state.fundsachen;
		const item = fundsachen.find(f => f.id === id);
		if (item && status) item.status = status;
		this.setState({ fundsachen });
		BackendInterface.postData("/vg/fundbuero/changeFundsache", { id, status, showImages }, (response: any) => {
			showNotification(response);
		});
	};
	rotate = (direction: "left" | "right") => {
		const imageRotations = this.state.imageRotations;
		const sum = direction === "left" ? -1 : 1;
		if (!imageRotations[this.state.currentIndex]) {
			imageRotations[this.state.currentIndex] = [];
		}
		if (!imageRotations[this.state.currentIndex][this.state.currImg[this.state.currentIndex]]) {
			imageRotations[this.state.currentIndex][this.state.currImg[this.state.currentIndex]] = sum;
		} else {
			imageRotations[this.state.currentIndex][this.state.currImg[this.state.currentIndex]] += sum;
		}
		this.setState({ imageRotations });
	};
	istUngerade = (zahl: number) => {
		return zahl % 2 !== 0;
	};

	getRotation = (currentIndex: number, imageIndex: number) => {
		const rotations = this.state.imageRotations[currentIndex];
		if (rotations && rotations[imageIndex]) {
			return "rotate(" + rotations[imageIndex] * 90 + "deg)";
		} else {
			return "rotate(0deg)";
		}
	};

	showImages = (id: any, values: any) => {
		this.changeStatus(id, values.showImages);
	};

	rotateCurrentImage = () => {
		const img = document.querySelector("#viewerBackdrop > div > figure > img") as HTMLElement;
		const currentIndex = this.state.currentIndex;
		if (img) {
			if (this.state.imageRotations[currentIndex] && this.state.imageRotations[currentIndex][this.state.currImg[currentIndex]]) {
				const rotation = this.state.imageRotations[currentIndex][this.state.currImg[currentIndex]];
				img.style.transform = "rotate(" + rotation * 90 + "deg)";
			} else {
				img.style.transform = "rotate(0deg)";
			}
		}
	};

	/*******************************************************************************
	 * 									RENDER
	 */

	render() {
		const { fundsachen } = this.state;
		return (
			<DashboardContent className="fundsachen-wrapper">
				{this.state.currentIndex !== -1 && (
					<div className="image-tools-wrapper">
						<div className="image-tools">
							<button onClick={() => this.rotate("left")}>Rotate Left</button>
							<button onClick={() => this.rotate("right")}>Rotate Right</button>
						</div>
					</div>
				)}
				<OneDashCard title="Genehmigungen">{this.fundsachenContent(fundsachen, ["waiting"], this.waitingTrigger)}</OneDashCard>
				<OneDashCard title="Aktuelle Fundsachen">
					{this.fundsachenContent(fundsachen, ["published"], this.publishedTrigger)}
				</OneDashCard>
			</DashboardContent>
		);
	}

	/*******************************************************************************
	 *
	 */

	fundsachenContent = (fundsachen: Fundsache[], states: string[], triggerFunction: (id: any, location: any, type: any) => any) => {
		return (
			<div className="fundsachen">
				{fundsachen.map((fundsache, collIndex) => {
					if (!states.includes(fundsache.status)) return undefined;
					return (
						<Collapsible
							easing="ease-in-out"
							lazyRender
							key={fundsache.id}
							trigger={triggerFunction(fundsache.id, fundsache.objectLocation, fundsache.objectType)}>
							<div className="fundsache">
								<div className="fundsache-data">
									<ul>
										<li>
											<div className="title">Fundort</div>
											<div className="value">{fundsache.objectAddress}</div>
										</li>
										<li>
											<div className="title">Fundumstände</div>
											<div className="value">{fundsache.objectAdditionalData}</div>
										</li>
										<li>
											<div className="title">Datum</div>
											<div className="value">{dayjs(Number(fundsache.creationDate)).format("DD.MM.YYYY")}</div>
										</li>
									</ul>
								</div>
								<div className="finder-data">
									<ul>
										<li>
											<div className="title">Name</div>
											<div className="value">
												{fundsache.firstName} {fundsache.lastName}
											</div>
										</li>
										<li>
											<div className="title">Email Adresse</div>
											<div className="value">
												<a href={`mailto:${fundsache.email}`}>{fundsache.email}</a>
											</div>
										</li>
										<li>
											<div className="title">Telefonnummer</div>
											<div className="value">
												<a href={`tel:${fundsache.phone}`}>{fundsache.phone}</a>
											</div>
										</li>
									</ul>
								</div>
								<div className="finder-image-checkbox">
									<OneDashForm onChange={values => this.showImages(fundsache.id, values)}>
										<OneDashInput
											value={String(fundsache.showImages)}
											label="Bilder anzeigen"
											name="showImages"
											type="boolean"
										/>
									</OneDashForm>
								</div>
								{fundsache.files && (
									<>
										<div className="thumbail-list">
											{fundsache.files &&
												fundsache.files.map((file, i) => (
													<div key={i}>
														{(file.visible as any) == "1" && (
															<div className="thumbnail-item" key={file.id}>
																<a
																	href={BackendInterface.buildInlinePath(fileLocationPath + file.id)}
																	onClick={e => this.openImgsViewer(collIndex, i, e)}>
																	<div className="thumbnail-wrapper">
																		<img
																			className="thumbnail"
																			alt="Fundstückbild"
																			src={BackendInterface.buildInlinePath(
																				fileLocationPath + file.id
																			)}
																			style={{
																				transform: this.getRotation(collIndex, i)
																			}}
																		/>
																	</div>
																</a>
																{/*<a
															href={BackendInterface.buildInlinePath(
																fileLocationPath +
																	file +
																	"?type=download"
															)}
															download>
															<i className="fas fa-download"></i>
															</a>*/}
															</div>
														)}
													</div>
												))}
										</div>

										<ImgsViewer
											onClickNext={() => this.updateCurrentImage(collIndex, "+")}
											onClickPrev={() => this.updateCurrentImage(collIndex, "-")}
											isOpen={this.state.isOpen[collIndex]}
											currImg={this.state.currImg[collIndex]}
											leftArrowTitle="Zurück"
											rightArrowTitle="Nach Vorne"
											closeBtnTitle="Schließen"
											customCtrls={this.customControls()}
											onClose={() => this.closeImgsViewer(collIndex)}
											imgs={fundsache.files.map(file => {
												return {
													src: BackendInterface.buildInlinePath(fileLocationPath + file.id),
													orientation: "square"
												};
											})}
										/>

										{this.rotateCurrentImage()}
									</>
								)}
							</div>
						</Collapsible>
					);
				})}
			</div>
		);
	};
}

export default Fundsachen;
