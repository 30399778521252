import React, { Component } from "react";
import DashboardContent from "../Dashboard/DashboardContent";

export interface SettingsProps {}

export interface SettingsState {}

class Settings extends Component<SettingsProps, SettingsState> {
	componentDidMount = () => {};

	render() {
		return <DashboardContent align="center" showLoadingSpinner={false} cardDisplayType="formular"></DashboardContent>;
	}
}
export default Settings;
