import React, { Component } from "react";
/* Datepicker */
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import "moment/locale/de";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Utils } from "../../Helpers/Utils";
import { BasicInputProps } from "../../Interfaces";

class BasicInput extends Component<BasicInputProps> {
	returnInputElement = (id: string) => {
		switch (this.props.type) {
		case "date-range":
			return ( 
				<div className="date-range">
					<RangeDatePicker
						startPlaceholder="Ankunft"
						endPlaceholder="Abreise"
						readOnly={false}
						dateFormat="DD.MM.YYYY"
						className="form-input"
						onChange={this.props.callback}
						showMonthCnt={1}
					/>
					<div className="left-range-icon">
						<i className={this.props.rangeIcon1} />
					</div>
					<div className="right-range-icon">
						<i className={this.props.rangeIcon2} />
					</div>
				</div>
			);

		default:
			return <input id={id} className="form-input" name={this.props.text} type={this.props.type} onChange={this.props.callback} />;
		}
	};
	returnMainClass = () => {
		switch (this.props.type) {
		case "date-range":
			return "form-date-range-group";

		default:
			return "form-group";
		}
	};

	render() {
		const id = Utils.generateGuid();
		return (
			<div className={this.returnMainClass()}>
				<i className={this.props.iconClass} />
				<label className="form-label" htmlFor={id}>
					{this.props.text}
				</label>
				{this.returnInputElement(id)}
			</div>
		);
	}
}

export default BasicInput;
