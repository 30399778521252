import { toast } from "react-toastify";

type response = {
	message: string;
	type: "E" | "S" | "W";
};

export function showNotification(response: response, position: "bottom-left" | "bottom-right" = toast.POSITION.BOTTOM_RIGHT) {
	try {
		switch (response.type) {
		case "E":
			toast.error(response.message, {
				position
			});
			break;

		case "S":
			toast.success(response.message, {
				position
			});
			break;

		default:
			toast.warn(response.message, {
				position
			});
			break;
		}
	} catch (err) {}
}
