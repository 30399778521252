import { PagePrintStyles } from "../Interfaces";

export const PAGE_PRINT_STYLES: PagePrintStyles = {
	"standard-card": "@page {size:210mm 297mm;margin: 0px;}",
	"drink-card": "@page {size: 210mm 207mm; margin: 0px} body{background:white !important}",
	invoice: "",
	timeManagement: "",
};

export class PrintSettings {
	static setPrintSettings(printStyle: keyof PagePrintStyles) {
		const styleEl = document.querySelector(".print-page-style");
		if (styleEl && styleEl.parentNode) {
			styleEl.parentNode.removeChild(styleEl);
		}
		const newStyleEl = document.createElement("style");
		newStyleEl.classList.add("print-page-style");
		newStyleEl.innerHTML = PAGE_PRINT_STYLES[printStyle];
		const ref = document.querySelector("script");
		if (ref && ref.parentNode) {
			ref.parentNode.insertBefore(newStyleEl, ref);
		}
	}
	static removePrintSettings() {
		const styleEl = document.querySelector(".print-page-style");
		if (styleEl && styleEl.parentNode) {
			styleEl.parentNode.removeChild(styleEl);
		}
	}
}

export class Utils {
	static authWarningPrinted = false;

	static generateGuid = () => {
		const S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
	};

	static asyncForEach = async (array: any[], callback: Function) => {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	};

	static removeElementFromArray = (array: any[], element: any) => {
		return array.filter((el) => el !== element);
	};

	/**
	 * Page or component loads currently data.
	 */
	static contentIsLoading = () => {
		window.reduxStore.dispatch({ type: "NAVIGATION_LOADING_ON" });
	};

	/**
	 * Update is available for application
	 */
	static updateAvailable = () => {
		window.reduxStore.dispatch({ type: "UPDATE_AVAILABLE" });
	};

	/**
	 * Application Offline
	 */
	static appIsOffline = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_OFFLINE" });
	};
	/**
	 * Application installable
	 */
	static isInstallable = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_INSTALLABLE" });
	};
	/**
	 * Application Online
	 */
	static appIsOnline = () => {
		window.reduxStore.dispatch({ type: "APPLICATION_ONLINE" });
	};

	/**
	 * Loading process of Page or component finished.
	 */
	static contentIsLoaded = () => {
		window.reduxStore.dispatch({ type: "NAVIGATION_LOADING_OFF" });
	};

	/**
	 * Loading process of Page or component finished.
	 */
	static applicationError = (errorMessage: string) => {
		window.reduxStore.dispatch({ type: "APPLICATION_ERROR", message: errorMessage });
	};

	static waitForAccessToken = () => {
		return new Promise((resolve) => {
			const store = window.reduxStore;
			if (store.getState().accessTokenLoaded === true) {
				resolve();
				return;
			}
			const unsubscribe = store.subscribe(() => {
				if (store.getState().accessTokenLoaded === true) {
					resolve();
					unsubscribe();
					return;
				}
			});
		});
	};

	static openScrollLock = () => {
		const body = document.querySelector("body");
		if (body) {
			body.classList.add("scrollingEnabled");
			body.classList.remove("scrollingDisabled");
		}
	};

	static setCurrentPage = (moduleName: string) => {
		window.reduxStore.dispatch({ type: "SET_CURRENT_PAGE", pageName: moduleName });
	};

	static isOffline = () => {
		if (
			!Utils.authWarningPrinted &&
			localStorage.getItem("offlineMode") === "true" &&
			window.location.origin === "http://localhost:3000"
		) {
			Utils.authWarningPrinted = true;
			console.warn("Localhost offline auth mode is activated");
			return true;
		} else {
			return false;
		}
	};

	static getAppId = () => {
		return window.location.pathname.split("/").pop();
	}
}
