import AXIOS from "axios";
import { showNotification } from "./Notify";
import { Utils } from "./Utils";

interface Request {
	path: string;
	callback: any;
	appIdNececssary: boolean;
}

export class BackendInterface {
	private static backendDataServer = process.env.REACT_APP_API_URL;
	private static waitingRequests = [] as Request[];

	private static checkPath = (path: string, appIdNececssary: boolean) => {
		if (path.charAt(0) !== "/") {
			path = "/" + path;
		}
		//Get AppId
		if (appIdNececssary) {
			const appId = window.location.pathname.split("/").pop();
			if (appId && appId.length >= 0) {
				path = "/" + appId + path;
			}
		}
		return path;
	};
	static getAsyncDataByUrl = async (path: string, appIdNececssary = true) => {
		path = BackendInterface.checkPath(path, appIdNececssary);
		const url = BackendInterface.backendDataServer + path;
		await Utils.waitForAccessToken();
		const accessToken = window.auth.getAccessToken();
		return (await AXIOS.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })).data;
	};

	static buildInlinePath = (path: string, appIdNececssary = true) => {
		path = BackendInterface.checkPath(path, appIdNececssary);
		return BackendInterface.backendDataServer + path;
	};

	static getDataByUrl = (path: string, callback: (data: any) => void, appIdNececssary = true) => {
		const store = window.reduxStore;
		const accessTokenLoaded = store.getState().accessTokenLoaded;
		if (!accessTokenLoaded && Utils.isOffline() === false) {
			BackendInterface.waitingRequests.push({
				path,
				callback,
				appIdNececssary
			});
		} else {
			BackendInterface.callRequest(path, callback, appIdNececssary);
		}

		store.subscribe(() => {
			const storeState = store.getState();
			if (storeState.accessTokenLoaded === true) {
				for (const request of BackendInterface.waitingRequests) {
					BackendInterface.callRequest(request.path, request.callback, request.appIdNececssary);
				}
				BackendInterface.waitingRequests = [];
			}
		});
	};

	private static callRequest(path: string, callback: any, appIdNececssary = true) {
		let headers = undefined;
		if (Utils.isOffline() === false) {
			headers = { Authorization: `Bearer ${window.auth.getAccessToken()}` };
		}
		path = BackendInterface.checkPath(path, appIdNececssary);
		AXIOS.get(BackendInterface.backendDataServer + path, {
			headers
		}).then(response => {
			if (response) {
				callback(response.data);
			} else {
				console.error(BackendInterface.backendDataServer + path);
				callback({});
			}
		});
	}

	static postData = (path: string, postData: any, callback: Function = () => {}, appIdNececssary = true) => {
		path = BackendInterface.checkPath(path, appIdNececssary);
		let headers = undefined;
		if (Utils.isOffline() === false) {
			headers = { Authorization: `Bearer ${window.auth.getAccessToken()}` };
		}
		AXIOS.post(BackendInterface.backendDataServer + path, postData, {
			headers
		}).then(function(response) {
			try {
				if (response.data.type === "E") {
					showNotification(response.data);
					callback(response.data);
				} else {
					callback(response.data);
				}
			} catch (ex) {}
		});
	};
}
