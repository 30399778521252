import React, { Component } from "react";
import { OneDashSpinner } from "@onedash/tools";

export interface DashboardContentProps {
	align?: "center" | "left" | "right"; //Default left
	maxWidth?: boolean; // Default false
	showLoadingSpinner?: boolean; //Default true
	cardDisplayType?: "default" | "process" | "formular" | "table";
	className?: string;
	error?: string;
}

class DashboardContent extends Component<DashboardContentProps> {
	state = {
		contentLoaded: false,
		error: undefined
	};
	spinner = React.createRef<OneDashSpinner>();

	subscribeNavigationStore = () => {
		const store = window.reduxStore;
		store.subscribe(() => {
			const storeState = store.getState();
			if (storeState.navLoading === this.state.contentLoaded) {
				const s = this.spinner.current;
				if (s) {
					s.hide();
				}
				this.setState({
					contentLoaded: !storeState.navLoading
				});
			}
			this.setState({
				error: storeState.applicationErrorMessage
			});
		});
	};
	componentWillUnmount() {
		window.reduxStore.dispatch({ type: "RESET_APPLICATION_ERROR" });
	}
	componentDidMount() {
		this.subscribeNavigationStore();
	}
	getClasses = () => {
		const p = this.props;
		let classes = "dashboard-content-item ";
		if (p.align) {
			switch (p.align) {
			case "center":
				classes += "dashboard-content-item-centered ";
				break;
			case "right":
				classes += "dashboard-content-item-right ";
				break;
			}
		}
		if (p.maxWidth) {
			classes += "dashboard-content-item-maxWidth ";
		}
		if (p.cardDisplayType === "process") {
			classes += "dashboard-content-item-process ";
		}
		if (p.cardDisplayType === "formular") {
			classes += "dashboard-content-item-formular ";
		}
		if (p.cardDisplayType === "table") {
			classes += "dashboard-content-item-table ";
		}
		if (p.showLoadingSpinner !== false && this.state.contentLoaded === false) {
			classes += "dashboard-content-item-hidden ";
		}
		if (p.className) {
			classes += p.className;
		}

		return classes;
	};

	render() {
		return (
			<>
				{this.state.error && <h1 className="application-error">{this.state.error}</h1>}
				{!this.state.error && <div className={this.getClasses()}>{this.props.children}</div>}
				<OneDashSpinner
					ref={this.spinner}
					componentStyle="dark"
					defaultVisible={this.props.showLoadingSpinner === false ? false : true}
				/>
			</>
		);
	}
}

export default DashboardContent;
