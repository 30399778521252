import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./Components/Allgemein/App/App";
import * as serviceWorker from "./ServiceWorkerMain";
import { MAIN_SETTINGS } from "./Helpers/Settings";
import RouterHistory from "./Helpers/RouterHistory";

ReactDOM.render(
	<Router history={RouterHistory} basename={MAIN_SETTINGS.basename}>
		<App />
	</Router>,
	document.getElementById("root")
);

serviceWorker.register();
