import React from "react";
import "./Home.scss";
import { OneDashCard } from "@onedash/tools";

export interface HomeProps {}

export interface HomeState {}

class Home extends React.Component<HomeProps, HomeState> {
	render() {
		return <OneDashCard title="Homepage" className="home-page"></OneDashCard>;
	}
}

export default Home;
