import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { OneDashTable, OneDashCard, OneDashDialog, OneDashForm, OneDashSelect, OneDashInput } from "@onedash/tools";
import { tableHeader } from "@onedash/tools/dist/OneDashTable/OneDashTable";
import { ValueLabelPair } from "@onedash/tools/dist/OneDashForm/OneDashSelect";
import { isArray } from "util";
import { showNotification } from "../../../Helpers/Notify";

export interface ManageDataProps {}

class ManageData extends Component<ManageDataProps> {
	dialogRef = React.createRef<OneDashDialog>();
	tableRef = React.createRef<OneDashTable>();
	state = {
		tableHeaders: [],
		tableValues: [],
		password: "",
		locationValues: [],
		users: []
	};
	componentDidMount() {
		this.reloadTable();
	}

	loadUserRoles = (data: any) => {
		const tableData: any[] = this.state.tableValues;
		const index = tableData.indexOf(data as never);
		if (index !== -1) {
			BackendInterface.getDataByUrl(
				"/user/getRoles/" + tableData[index]["id"],
				data => {
					if (!isArray(data)) {
						this.tableRef.current && this.tableRef.current.closeDialog();
						showNotification({ type: "E", message: "User konnte nicht geladen werden." });
						return;
					}
					const roles = [];
					for (const role of data) {
						roles.push(role.id);
					}
					tableData[index]["roles"] = roles;
					this.setState({ tableValues: tableData });
				},
				true
			);
		} else {
			this.tableRef.current && this.tableRef.current.closeDialog();
			showNotification({ type: "E", message: "User konnte nicht geladen werden." });
		}
	};

	reloadTable = () => {
		this.getTableHeader(headers => {
			Utils.contentIsLoading();
			this.getTableValues(values => {
				this.setState({ tableHeaders: headers, tableValues: values }, Utils.contentIsLoaded);
				this.getUserSelect();
			});
		});
	};

	getTableHeader = (callback: (headers: any) => void): void => {
		const headers: tableHeader[] = [];
		headers.push({ columnName: "name", type: "text", title: "Account Name", visible: true });
		headers.push({ columnName: "username", type: "text", title: "Username", visible: true });
		headers.push({ columnName: "email", type: "email", title: "E-Mail", visible: true });
		headers.push({ columnName: "prename", type: "text", title: "Vorname", visible: true });
		headers.push({ columnName: "surname", type: "text", title: "Nachname", visible: true });
		headers.push({ columnName: "password", type: "text", title: "Initial Passwort: Wird nur einmal angezeigt!", visible: false });
		this.getAllRoles(data => {
			headers.push({ columnName: "roles", type: "tag-input", title: "Roles", visible: false, selectValueLabelPair: data });
			callback(headers);
		});
	};

	getAllRoles = (callback: (data: any) => void) => {
		BackendInterface.getDataByUrl("/auth/getRoles", (data: any) => {
			const roles = [];
			for (const role of data) {
				roles.push({ label: role.name, value: role.authId });
			}
			callback(roles);
		});
	};

	getTableValues = (callback: (values: any) => void): void => {
		BackendInterface.getDataByUrl("/user/application/get", data => {
			callback(data);
		});
	};

	getUserSelect = () => {
		const users: ValueLabelPair[] = [];
		this.state.tableValues.forEach((user: any) => {
			users.push({ label: user.username, value: user.id });
		});

		this.setState({ users: users });
	};

	onSave = (values: any) => {
		BackendInterface.postData("/user/saveUser", values, (response: any) => {
			if (response.type === "S" && response.message.password !== undefined) {
				const tableData: any[] = this.state.tableValues;
				const index = tableData.indexOf(values);

				const status = response.message;

				if (index !== -1) {
					tableData[index]["id"] = status.user_id;
					tableData[index]["password"] = status.password;
				} else {
					values["id"] = status.user_id;
					values["password"] = status.password;
					tableData.push(values);
				}
				this.setState({ password: status.password, tableValues: tableData });
			}
		});
	};

	onDeleteUser = (values: any, row: any) => {
		BackendInterface.getDataByUrl(`/user/delete/${values}`, data => {
			let tableData = this.state.tableValues;
			tableData = tableData.filter((value: any) => {
				if (value.email !== row.email) {
					return true;
				} else {
					return false;
				}
			});
			this.setState({ tableValues: tableData });
		});
	};

	onResetPassword = (values: any) => {
		if (values.user !== undefined && values.user !== "invalid-input") {
			BackendInterface.postData("/user/resetPassword", { userId: values.user }, (data: any) => {
				if (data.password !== undefined) {
					this.setState({ password: data.password });
					this.dialogRef.current && this.dialogRef.current.toggleOpen();
				}
			});
		}
	};

	render() {
		return (
			<DashboardContent className="manage-users">
				<OneDashDialog closeable ref={this.dialogRef}>
					<h4>Passwort:</h4>
					<p>{this.state.password}</p>
					<label>Bitte merken! Passwort wird nicht erneut angezeigt!</label>
				</OneDashDialog>
				<OneDashCard tableCard title="Benutzerverwaltung">
					<OneDashTable
						ref={this.tableRef}
						onClick={{ click: this.loadUserRoles, openDialog: true }}
						onSave={this.onSave}
						onDelete={this.onDeleteUser}
						editable
						tableHeaders={this.state.tableHeaders}
						tableValues={this.state.tableValues}></OneDashTable>
				</OneDashCard>
				<OneDashCard title="Passwort zurücksetzen">
					<OneDashForm onSubmit={this.onResetPassword}>
						<OneDashSelect name="user" options={this.state.users}></OneDashSelect>
						<OneDashInput type="submit" value="Passwort zurücksetzen" name=""></OneDashInput>
					</OneDashForm>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default ManageData;
