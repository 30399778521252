import React, { Component } from "react";
import RouterHistory from "../../../Helpers/RouterHistory";
import { OneDashSpinner } from "@onedash/tools";

export interface StartingApplicationProps {}

class StartingApplication extends Component<StartingApplicationProps> {
	state = {
		noApplications: false
	};
	componentDidMount() {
		const applications = window.reduxStore.getState().userApplications;

		if (applications.length > 0 && applications[0].modules.length > 0) {
			const route = applications[0].modules[0].route;
			const appId = applications[0].appId;
			RouterHistory.push(route + "/" + appId);
		} else {
			this.setState({ noApplications: true });
		}
	}
	render() {
		return (
			<div className="starting-application">
				{!this.state.noApplications && <OneDashSpinner componentStyle="light" defaultVisible={true} />}
				{this.state.noApplications && (
					<h1>
						Ihnen wurde noch keine Anwendung zugewiesen.<br></br> Wenden Sie sich an den Support.
					</h1>
				)}
			</div>
		);
	}
}

export default StartingApplication;
