import React, { Component } from "react";
import "./Mediathek.scss";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { OneDashCard, OneDashForm, OneDashInput, OneDashTagInput } from "@onedash/tools";
import { Utils } from "../../../Helpers/Utils";
import Collapsible from "react-collapsible";
import InfiniteScroll from "react-infinite-scroll-component";

import { showNotification } from "../../../Helpers/Notify";

interface MediathekProps {}

class Mediathek extends Component<MediathekProps> {
	currentPage = 0;
	state = {
		movies: [] as Movie[],
		tableHeaders: [],
		movieCount: 0,
		tags: [],
	};
	loadMovies = async () => {
		const movieResponse: MovieResponse = await BackendInterface.getAsyncDataByUrl(`/mediathek/movie?page=${this.currentPage + 1}`);
		const movies = this.state.movies.concat(movieResponse.message.movies);

		movies.forEach((movie) => {
			movie.id = movie.movieId;
		});
		this.setState({ movies, movieCount: movieResponse.message.entryCount });
		Utils.contentIsLoaded();
	};

	tagChange = (newTags: any, entry: any) => {
		const movies = this.state.movies;
		const movie = movies.find((x) => x.id === entry.id);
		if (movie) {
			movie.tags = newTags;
			this.setState({ movies });
		}
	};

	loadMore = () => {
		this.currentPage++;
		this.loadMovies();
	};

	changeState = (entry: any) => {
		const movies = this.state.movies;
		const movie = movies.find((x) => x.id === entry.id);
		if (movie) {
			if (movie.state === "pending" || movie.state === "declined") {
				movie.state = "accepted";
				BackendInterface.getAsyncDataByUrl(`/mediathek/acceptMovie/${movie.id}`);
			} else {
				movie.state = "declined";
				BackendInterface.getAsyncDataByUrl(`/mediathek/declineMovie/${movie.id}`);
			}
		}
		this.setState({ movies });
	};

	deleteVideo = (movie: Movie) => {
		const movies = this.state.movies;
		const movieIndex = movies.findIndex((x) => x.id === movie.id);

		if (movieIndex !== -1) {
			movies.splice(movieIndex, 1);
			BackendInterface.postData("/mediathek/deleteMovie", { url: movie.url });
		}
		this.setState({ movies });
	};

	addVideo = (data: any, form: OneDashForm) => {
		form.resetForm();
		BackendInterface.postData("/mediathek/movie", { url: data["url"] }, async (response: any) => {
			showNotification(response);
			if (response.type === "S") {
				const movieResponse: MovieResponse = await BackendInterface.getAsyncDataByUrl("/mediathek/movie?pageSize=1");
				const movies = movieResponse.message.movies.concat(this.state.movies);
				this.setState({ movies });
			}
		});
	};
	loadTags = async () => {
		const tags = (await BackendInterface.getAsyncDataByUrl("/mediathek/tags")).message.tags;
		this.setState({
			tags: tags.map((t: any) => {
				return { value: t, label: t };
			}),
		});
	};

	componentDidMount() {
		this.loadMovies();
		this.loadTags();
	}
	changeTag = (movie: Movie, tags: string[]) => {
		BackendInterface.postData("/mediathek/movie", { url: movie.url, tags });
		const movies = this.state.movies;
		const m = movies.find((x) => x.id === movie.id);
		if (m) {
			m.tags = tags;
		}
		this.setState({ movies });
	};

	render() {
		const waitingTrigger = (entry: Movie) => {
			return (
				<div className={"trigger " + entry.state}>
					<img src={entry.thumbnailUrl} alt={entry.title}></img>
					<div>
						<div className="title">{entry.title}</div>
						<div className="tags">{entry.tags.join(", ")}</div>
					</div>
				</div>
			);
		};
		const movies = this.state.movies;
		return (
			<DashboardContent className="mediathek">
				<OneDashCard title="Aktuelle Videos" maxWidth={800}>
					<OneDashForm onSubmit={this.addVideo}>
						<OneDashInput name="url" type="text" placeholder="Link zum Video" label="Videolink"></OneDashInput>
						<OneDashInput name="submit" type="submit" value="Video hinzufügen"></OneDashInput>
					</OneDashForm>
					<InfiniteScroll
						dataLength={this.state.movies.length} //This is important field to render the next data
						next={this.loadMore}
						hasMore={this.state.movieCount > this.state.movies.length}
						loader={<p>Laden ...</p>}
						scrollableTarget={
							window.innerWidth < 1200 ? document.querySelector("body") : document.querySelector(".content-wrapper")
						}
						endMessage={<h2 className="end-text">Keine weiteren Videos Verfügbar</h2>}>
						{movies.map((movie, index) => {
							return (
								<div className={movie.state} key={index}>
									<Collapsible easing="ease-in-out" trigger={waitingTrigger(movie)}>
										<div className="indicator">
											<span onClick={() => this.changeState(movie)}>
												{movie.state === "accepted" && <>Online</>}
												{movie.state === "pending" && <>Wartend</>}
												{movie.state === "declined" && <>Gesperrt</>}
											</span>
										</div>
										<div className="movie">
											<div className="entry">
												<p className="label">Beschreibung</p>
												<p className="value">{movie.description}</p>
											</div>
											<div className="flex">
												<div className="entry">
													<p className="label">Link zum Video</p>
													<a href={movie.url} className="value">
														{movie.url}
													</a>
												</div>
												<div className="entry">
													<p className="label">Länge des Videos</p>
													<p className="value">{movie.duration !== "00:00" ? movie.duration : "Keine Angabe"}</p>
												</div>
											</div>
											<OneDashTagInput
												onChange={(tags: any) => this.changeTag(movie, tags)}
												label="Kategorien"
												placeholder="Wählen Sie eine Kategorie"
												name="tags"
												tags={this.state.tags}
												value={movie.tags}
											/>
										</div>
										<button className="movie-delete" onClick={() => this.deleteVideo(movie)}>
											Video löschen
										</button>
									</Collapsible>
								</div>
							);
						})}
					</InfiniteScroll>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default Mediathek;
