import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { OneDashTable, OneDashCard } from "@onedash/tools";

export interface ManageDataProps {}

class ManageData extends Component<ManageDataProps> {
	state = {
		locationValues: [] as any[],
		locationHeaders: [] as any[]
	};

	componentDidMount() {
		BackendInterface.getDataByUrl("/standard/locations", (data: { tableHeaders: any[]; tableData: any[] }) => {
			this.setState({ locationHeaders: data.tableHeaders, locationValues: data.tableData }, Utils.contentIsLoaded);
		});
	}

	saveRoute = (values: any, tableValues: any[]) => {
		this.setState({ locationValues: tableValues });
		const entry = values;
		BackendInterface.postData("/standard/locations/save", entry, (response: any) => {
			if (response.type === "E") {
				const values = this.state.locationValues;
				values.pop();
				this.setState({ locationValues: values });
			}
		});
	};

	deleteRoute = (id: number) => {
		BackendInterface.postData("/standard/locations/delete", { id }, (response: any) => {});
	};
	render() {
		return (
			<DashboardContent cardDisplayType="table">
				<OneDashCard title="Orte" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.locationHeaders}
						onSave={this.saveRoute}
						onDelete={this.deleteRoute}
						tableValues={this.state.locationValues}></OneDashTable>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default ManageData;
