import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BackendInterface } from "../../Helpers/BackendInterface";
import { Utils } from "../../Helpers/Utils";
import DashboardContent from "../Allgemein/Dashboard/DashboardContent";
import { OneDashCard, OneDashForm, OneDashInput } from "@onedash/tools";

type price = {
	type: "room";
	priceCategory: number;
	price: number;
	roomType: "dz" | "ez";
};

type priceCategory = {
	priceCategory: number;
	elements: string[];
	prices: price[];
};

class RoomPrices extends Component {
	state = {
		taxes: {},
		breakfast: [],
		categories: [],
		contribution: [],
		menu: {} as any,
		dog: [],
		roomPrices: [] as priceCategory[]
	};

	componentDidMount = () => {
		this.getPrices();
	};

	async getPrices() {
		const roomCategories = await BackendInterface.getAsyncDataByUrl("/rooms/categories");
		const roomPrices = (await BackendInterface.getAsyncDataByUrl("/prices/rooms")) as price[];
		roomCategories.forEach((category: priceCategory) => {
			category.prices = roomPrices.filter(x => x.priceCategory === category.priceCategory);
		});

		this.setState(
			{
				taxes: (await BackendInterface.getAsyncDataByUrl("/prices/taxes"))[0],
				breakfast: await BackendInterface.getAsyncDataByUrl("/prices/breakfast"),
				categories: await BackendInterface.getAsyncDataByUrl("/rooms/categories"),
				contribution: await BackendInterface.getAsyncDataByUrl("/prices/contribution"),
				menu: {
					3: await BackendInterface.getAsyncDataByUrl("/prices/menu/3"),
					5: await BackendInterface.getAsyncDataByUrl("/prices/menu/5")
				},
				dog: await BackendInterface.getAsyncDataByUrl("/prices/dog"),
				roomPrices: roomCategories
			},
			this.checkLoadingFinished
		);
	}

	checkLoadingFinished = () => {
		Utils.contentIsLoaded();
	};

	getInitialValue = (stateName: string, filterName: string, fiterValue: string, valueProperty: string) => {
		const state = (this.state as any)[stateName];
		if (state) {
			const el = state.find((x: any) => x[filterName] === fiterValue);
			if (el) {
				return el[valueProperty];
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	taxFormat = (taxName: "hotel" | "standard") => {
		return String(Number(this.state.taxes && (this.state.taxes as any)[taxName]) * 100);
	};
	roomPriceFormat = (prices: price[], type: "dz" | "ez") => {
		const price = prices.find(x => x.roomType === type);
		if (price) {
			return String(price.price);
		} else {
			return "0";
		}
	};

	roomPriceChange = (category: priceCategory, values: any) => {
		Object.keys(values).forEach(prop => {
			const price = Number(values[prop].replace(",", "."));
			BackendInterface.postData(`/prices/room/${category.priceCategory}/${prop}`, { price });
		});
	};
	breakFastChange = (values: any) => {
		Object.keys(values).forEach(prop => {
			const price = Number(values[prop].replace(",", "."));
			BackendInterface.postData(`/prices/breakfast/${prop}`, { price });
		});
	};
	priceChange = (values: any, propName: any) => {
		const price = Number(values[propName].replace(",", "."));
		BackendInterface.postData(`/prices/${propName}`, { price });
	};
	hpChange = (values: any) => {
		Object.keys(values).forEach(prop => {
			const price = Number(values[prop].replace(",", "."));
			BackendInterface.postData(`/prices/menu/${prop}`, { price });
		});
	};
	taxChange = (taxes: any) => {
		Object.keys(taxes).forEach(prop => {
			const tax = Number(taxes[prop].replace("%", "").replace(",", ".")) / 100;
			BackendInterface.postData("/prices/taxes/", { type: prop, tax });
		});
	};

	render() {
		return (
			<DashboardContent align="center">
				<OneDashCard title="Zimmerpreise">
					{this.state.roomPrices.map(category => (
						<OneDashForm
							onChange={values => this.roomPriceChange(category, values)}
							key={category.priceCategory}
							listStyle="pair"
							listStyleLabelToolTip={category.elements.join(", ")}
							listStyleLabel={`Preiskategorie ${category.priceCategory}`}>
							<OneDashInput
								value={this.roomPriceFormat(category.prices, "dz")}
								name="dz"
								type="euro"
								label="Doppelzimmer"
							/>
							<OneDashInput
								value={this.roomPriceFormat(category.prices, "ez")}
								name="ez"
								type="euro"
								label="Einzelzimmer"
							/>
						</OneDashForm>
					))}
				</OneDashCard>

				<OneDashCard title="Frühstück (p.P)">
					<OneDashForm listStyle="pair" onChange={this.breakFastChange}>
						<OneDashInput
							value={this.getInitialValue("breakfast", "roomType", "in", "price")}
							name="in"
							type="euro"
							label="Standard"
						/>
						<OneDashInput
							value={this.getInitialValue("breakfast", "roomType", "ex", "price")}
							name="ex"
							type="euro"
							label="Zusatz"
						/>
					</OneDashForm>
				</OneDashCard>

				<OneDashCard title="Gästebeitrag">
					<OneDashForm onChange={val => this.priceChange(val, "contribution")}>
						<OneDashInput
							value={this.getInitialValue("contribution", "type", "contribution", "price")}
							name="contribution"
							type="euro"
							label="Erwachsene"
						/>
					</OneDashForm>
				</OneDashCard>

				<OneDashCard title="Halbpension">
					<OneDashForm onChange={this.hpChange}>
						<OneDashInput
							value={this.state.menu[3] && this.state.menu[3][0].price}
							name="3"
							type="euro"
							label="3 Gang"
						/>
						<OneDashInput
							value={this.state.menu[5] && this.state.menu[5][0].price}
							name="5"
							type="euro"
							label="5 Gang"
						/>
					</OneDashForm>
				</OneDashCard>

				<OneDashCard title="Hund">
					<OneDashForm onChange={val => this.priceChange(val, "dog")}>
						<OneDashInput
							value={this.state.dog[0] && (this.state.dog[0] as any).price}
							name="dog"
							type="euro"
							label="Hund (pro Tag)"
						/>
					</OneDashForm>
				</OneDashCard>

				<OneDashCard title="Umsatzsteuer">
					<OneDashForm onChange={this.taxChange}>
						<OneDashInput
							value={this.taxFormat("hotel")}
							name="hotel"
							type="percent"
							label="Hotelgewerbe"
						/>
						<OneDashInput
							value={this.taxFormat("standard")}
							name="standard"
							type="percent"
							label="Restaurant"
						/>
					</OneDashForm>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default withRouter<any, any>(RoomPrices);
