import React, { Component } from "react";
import Auth from "../../Auth/Auth";
import "./Login.scss";
import { withRouter } from "react-router-dom";
import { showNotification } from "../../Helpers/Notify";
import SHA from "crypto-js/sha256";
import { OneDashForm, OneDashSpinner, OneDashInput } from "@onedash/tools";
import RouterHistory from "../../Helpers/RouterHistory";
import { Utils } from "../../Helpers/Utils";
interface LoginProps {
	auth: Auth;
}
class Login extends Component<LoginProps> {
	state = {
		showResetForm: false
	};
	spinner = React.createRef<OneDashSpinner>();
	loginForm = React.createRef<OneDashForm>();
	resetForm = React.createRef<OneDashForm>();

	loginUser = (values: any) => {
		const spinner = this.spinner.current;
		if (spinner) spinner.show();
		const username = values["username"];
		const password = values["password"];
		localStorage.setItem("password", SHA(password).toString());
		if (this.loginForm.current) this.loginForm.current.resetForm();
		this.props.auth.loginByUsernameAndPassword(username, password, (err, result) => {
			if (err) {
				if (Utils.isOffline() === true) {
					RouterHistory.push("/");
				} else {
					showNotification(
						{
							type: "E",
							message: "Das eingegebene Passwort ist inkorrekt"
						},
						"bottom-left"
					);
				}
			}
			if (spinner) spinner.hide();
		});
	};

	resetPassword = (values: any) => {
		const email = values.email;
		if (this.resetForm.current) this.resetForm.current.resetForm();
		this.props.auth.resetPassword(email, isValid => {
			if (isValid) {
				showNotification({
					message: "Falls Sie ein Account bei uns haben, senden wir Ihnen eine Email zum Zurücksenden des Passworts",
					type: "S"
				});
				this.setState({ showResetForm: false });
			} else {
				showNotification({ message: "Es ist ein Fehler beim Zurücksetzen Ihres Passworts aufgetreten", type: "E" });
			}
		});
	};

	render() {
		return (
			<div className="login-page">
				<div className="left-section">
					<div className="left-section-layer" />
				</div>
				<div className="right-section">
					<div>
						<div className="title">
							<h2>Herzlich Willkommen</h2>
							<h3>OneDash.</h3>
						</div>
						{!this.state.showResetForm && (
							<>
								<OneDashForm validateOnSubmit ref={this.loginForm} onSubmit={this.loginUser}>
									<OneDashInput name="username" type="text" required label="Username" requiredNotVisible />
									<OneDashInput name="password" type="password" required label="Passwort" requiredNotVisible />

									<OneDashInput name="submit" type="submit" value="Login" />
								</OneDashForm>
								<div className="additional-links" onClick={() => this.setState({ showResetForm: true })}>
									<button>Sie haben Ihr Passwort vergessen?</button>
								</div>
							</>
						)}
						{this.state.showResetForm && (
							<>
								<OneDashForm validateOnSubmit ref={this.resetForm} onSubmit={this.resetPassword}>
									<OneDashInput name="email" type="email" required label="Ihre Email Adresse" requiredNotVisible />
									<OneDashInput name="submit" type="submit" value="Passwort zurücksetzten" />
								</OneDashForm>
								<div className="additional-links" onClick={() => this.setState({ showResetForm: false })}>
									<button>Zurück zum Login</button>
								</div>
							</>
						)}
					</div>
				</div>
				<OneDashSpinner ref={this.spinner} componentStyle="dark" />
			</div>
		);
	}
}
export default withRouter<any, any>(Login);
