import React, { Component } from "react";
import "./BackendError.scss";
import { Utils } from "../../../Helpers/Utils";
export interface BackendErrorProps {}

class BackendError extends Component<BackendErrorProps> {
	componentDidMount() {
		Utils.setCurrentPage("Backenderror");
	}
	render() {
		return (
			<div id="notfound">
				<div className="notfound">
					<div className="notfound-error">
						<h1>Oops!</h1>
						<h2>Der Server reagiert nicht</h2>
					</div>

					<p>Der Server antwortet nicht mehr auf Anfragen. Bitte versuchen Sie es in kürze neu die Applikation zu laden.</p>
					<a href="/">Neu versuchen</a>
				</div>
			</div>
		);
	}
}

export default BackendError;
