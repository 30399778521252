import { selectValueLabelPair, MultiLangText, Zusatzstoff, cardTypeOptions } from "../../Interfaces";

export const translations = {
	contains: {
		de: "Enthält:",
		en: "Contains:",
		fr: "Contient:"
	},
	perPerson: {
		de: "Pro Person",
		en: "Per person",
		fr: "Par personne"
	},
	correspondingWines: {
		de: "Auf Wunsch servieren wir Ihnen zu jedem Gang</br>ein Glas ausgesuchten Wein",
		en: "On request we can serve an </br>exquisite wine with every dish",
		fr: "Verre de vin aussocti à</br>chaque plat à la demande"
	},
	menuFor: {
		de: "Menü ab",
		en: "Menu from",
		fr: "Menu à partir de"
	},
	persons: {
		de: "Personen",
		en: "persons",
		fr: "personnes"
	},
	wineRecommendation: {
		de: "Unsere Weinempfehlung",
		en: "Our wine suggestion",
		fr: "Vin recommandés"
	},
	zusatzstoffe: {
		de: "Übersicht der Zusatzstoffe",
		en: "Additives",
		fr: "Aperçu des additifs"
	}
};
export const languages: selectValueLabelPair[] = [
	{
		label: "Deutsch",
		value: "de"
	},
	{
		label: "Englisch",
		value: "en"
	},
	{
		label: "Französisch",
		value: "fr"
	}
];

export const ZUSATZSTOFFE_LIST: Zusatzstoff[] = [
	{
		num: 1,
		text: { de: "Konservierungsstoffe", en: "Preservative", fr: "avec conservateur" }
	},
	{
		num: 2,
		text: { de: "Farbstoffe", en: "Food dye", fr: "avec colorant" }
	},
	{
		num: 3,
		text: { de: "Antioxidationsmittel", en: "Antioxidant", fr: "avec antioxydant" }
	},
	{
		num: 4,
		text: { de: "Süßungsmittel Saccharin", en: "Sweetener saccharin", fr: "avec de la saccharine édulcorante" }
	},
	{
		num: 5,
		text: { de: "Süßungsmittel Cyclamat", en: "Sweetener cyclamate", fr: "avec l'édulcorant cyclamate" }
	},
	{
		num: 6,
		text: {
			de: "Süßungsmittel Aspartam, enth. Phenylalaninquelle",
			en: "Sweetener aspartame, enth. Phenylalanine source",
			fr: "avec l'édulcorant aspartame, enth. Source de phénylalanine"
		}
	},
	{
		num: 7,
		text: { de: "Süßungsmittel Acesulfam", en: "Sweetener acesulfame", fr: "avec l'édulcorant acesulfame" }
	},
	{
		num: 8,
		text: { de: "Phosphat", en: "Phosphate", fr: "avec du phosphate" }
	},
	{
		num: 9,
		text: { de: "Geschwefelte Inhaltsstoffe", en: "Sulphurated ingredients", fr: "sulfaté" }
	},
	{
		num: 10,
		text: { de: "Chinin", en: "Quinine", fr: "contenant de la quinine" }
	},
	{
		num: 11,
		text: { de: "Koffein", en: "Caffeine", fr: "contenant de la caféine" }
	},
	{
		num: 12,
		text: { de: "Geschmacksverstärker", en: "Flavour enhancer", fr: "avec exhausteur de goût" }
	},
	{
		num: 13,
		text: { de: "Geschwärzte Inhaltsstoffe", en: "Blackened ingredients", fr: "noirci" }
	},
	{
		num: 14,
		text: { de: "Gewachste Inhaltsstoffe", en: "Waxed ingredients", fr: "ciré" }
	},
	{
		num: 15,
		text: { de: "Gentechnisch verändere Inhaltsstoffe", en: "Genetically modified ingredients", fr: "génétiquement modifié" }
	}
];
export const ALLERGENE_LIST: MultiLangText[] = [
	{
		de: "Sellerie",
		en: "Celery",
		fr: "Céleri"
	},
	{
		de: "Schalentiere",
		en: "Mollusks",
		fr: "Mollusques"
	},
	{
		de: "Nüsse",
		en: "Nuts",
		fr: "Noix"
	},
	{
		de: "Sesam",
		en: "Sesame",
		fr: "Sésame"
	},
	{
		de: "Soja",
		en: "Soy",
		fr: "Soy"
	},
	{
		de: "Eier",
		en: "Eggs",
		fr: "Oeufs"
	},
	{
		de: "Erdnüsse",
		en: "Peanuts",
		fr: "Cacahuètes"
	},
	{
		de: "Fisch",
		en: "Fish",
		fr: "Poisson"
	},
	{
		de: "Sulfite",
		en: "Sulfur dioxide",
		fr: "Dioxyde de soufre"
	},
	{
		de: "Lupine",
		en: "Lupine",
		fr: "Lupin"
	},
	{
		de: "Krebstiere",
		en: "Crustaceans",
		fr: "Crustacés"
	},
	{
		de: "Weizen",
		en: "wheat",
		fr: "Blé"
	},
	{
		de: "Milch",
		en: "Milk",
		fr: "Lait"
	},
	{
		de: "Senf",
		en: "Mustard",
		fr: "Moutarde"
	}
];

export const cardOptions = [
	{
		name: "Zusätzlicher Text",
		value: "additionalText"
	},
	{
		name: "Begleitende Weine",
		value: "corresponding-wines"
	},

	{
		name: "Weinempfehlung",
		value: "wine-recommendation"
	},
	{
		name: "Minimale Personenanzahl",
		value: "person-num"
	},
	{
		name: "Mengenangabe",
		value: "amount"
	},
	{
		name: "Preise",
		value: "prices"
	},
	{
		name: "Preis",
		value: "price"
	},
	{
		name: "Mehrzeilige Einträge",
		value: "multi-line-items"
	},
	{
		name: "Itembeschreibung",
		value: "item-text"
	}
];
export const cardTypes: cardTypeOptions[] = [
	{
		label: "Menükarte",
		value: "menu",
		options: ["additionalText", "corresponding-wines", "wine-recommendation", "price", "person-num"]
	},
	{
		label: "Speisekarte",
		value: "eat",
		options: ["additionalText", "corresponding-wines", "wine-recommendation", "prices"]
	},
	{
		label: "Getränkekarte",
		value: "drink",
		options: ["prices", "amount", "additionalText", "multi-line-items", "item-text"]
	}
];
export const pageOptions = [
	{
		name: "Inhaltsstoffe nicht ausweisen",
		value: "no-inhaltsstoffe"
	},
	{
		name: "Seitennamen anzeigen",
		value: "page-name"
	},
	{
		name: "Seitennummer anzeigen",
		value: "page-num"
	},
	{
		name: "Zentrierter Inhalt (Nur Kleine Karte)",
		value: "centered-content"
	},
	{
		name: "Einführungstext",
		value: "start-text"
	}
];
