import React, { Component } from "react";
import Auth from "./Auth";
import "./AuthCallback.scss";

interface AuthCallbackProps {
	auth: Auth;
	location: any;
}
class Callback extends Component<AuthCallbackProps> {
	componentDidMount = () => {
		// Handle authentication if expected values are in the URL.
		if (/access_token|id_token|error/.test(this.props.location.hash)) {
			this.props.auth.handleAuthentication();
		} else {
			throw new Error("Invalid callback URL.");
		}
	};
	render() {
		return (
			<div className="auth-callback">
				<div className="spinner">
					<div className="dot1" />
					<div className="dot2" />
				</div>
			</div>
		);
	}
}

export default Callback;
