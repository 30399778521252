/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import "./TableManager.scss";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { selectValueLabelPair } from "../../../Interfaces";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { Utils } from "../../../Helpers/Utils";
import { showNotification } from "../../../Helpers/Notify";
import { OneDashForm, OneDashCard, OneDashSelect, OneDashInput, OneDashTable } from "@onedash/tools";
import { tableHeader } from "@onedash/tools/dist/OneDashTable/OneDashTable";
export interface TableManagerProps {}

class TableManager extends React.Component<TableManagerProps> {
	state = {
		appList: [] as selectValueLabelPair[],
		tableList: [] as selectValueLabelPair[],
		selectedTable: {} as selectValueLabelPair,
		selectedTableHeaders: [] as tableHeader[],
		selectedTableHeaderValues: [] as any[]
	};
	currentSelectedAppId = "";
	entryTable = React.createRef<OneDashTable>();
	tableNameInputForm = React.createRef<OneDashForm>();

	loadGenericTables() {
		BackendInterface.getDataByUrl("/adminTools/applications", (applications: any[]) => {
			const appList: selectValueLabelPair[] = [];
			applications.forEach(application => {
				appList.push({
					label: application.appName,
					value: application.appId
				});
			});

			this.setState({ appList }, Utils.contentIsLoaded);
		});
	}

	componentDidMount() {
		this.loadGenericTables();
	}

	applicationChanged = (value: any) => {
		this.currentSelectedAppId = value;
		BackendInterface.getDataByUrl("/adminTools/tables/" + this.currentSelectedAppId, (tables: any[]) => {
			const tableList: selectValueLabelPair[] = [];
			if (tables)
				tables.forEach(table => {
					tableList.push({
						label: table.table_name,
						value: table.table_name
					});
				});
			this.setState({ tableList });
		});
	};

	tableChanged = (value: any) => {
		const tableName = value;
		if (tableName.length > 0) {
			this.setState({
				selectedTable: this.state.tableList.find(table => table.value === tableName)
			});
			this.getTableHeaders("TableHeaders", "selectedTableHeaders");
			this.getTableHeaders(tableName, "selectedTableHeaderValues");
		}
	};

	getTableHeaders = (table_name: string, stateName: any) => {
		BackendInterface.getDataByUrl(
			"/adminTools/table/" + this.currentSelectedAppId + "/" + table_name,
			(tableHeaders: tableHeader[]) => {
				this.setState({ [stateName]: tableHeaders });
			}
		);
	};

	addNewTable = () => {
		this.setState({ selectedTableHeaderValues: [], selectedTable: { label: "", value: "N/A" } });
		this.getTableHeaders("TableHeaders", "selectedTableHeaders");
	};
	/*saveEntry = (tValues: any) => {
		this.setState({
			selectedTableHeaderValues: tValues
		});
	};*/

	saveTable = () => {
		const table = this.entryTable.current;
		const tableNameForm = this.tableNameInputForm.current;
		if (table && tableNameForm) {
			const tableRows = table.getValues();

			const tableName = tableNameForm.getData()["table_name"];
			const appId = this.currentSelectedAppId;
			BackendInterface.postData("/adminTools/table/" + appId, { tableRows, tableName }, (response: any) => {
				showNotification(response);
			});
		}
	};
	render() {
		const { appList, tableList } = this.state;
		return (
			<DashboardContent cardDisplayType="table" align="center">
				<OneDashCard title="Generic Tabellen bearbeiten" tableCard={true} className="table-manager-page">
					<OneDashForm className="selection-form">
						<OneDashSelect
							onChange={this.applicationChanged}
							placeholder="Wählen Sie eine Application"
							name="app-select"
							options={appList}
						/>

						<OneDashSelect
							onChange={this.tableChanged}
							placeholder="Wählen Sie eine Tabelle"
							name="table"
							options={tableList}></OneDashSelect>
						<button onClick={this.addNewTable} className="add-entry">
							Neue Generic Tabelle
						</button>
					</OneDashForm>
					<OneDashForm className="table-name-form" ref={this.tableNameInputForm}>
						{this.state.selectedTable.value && this.state.selectedTable.value.length > 0 && (
							<OneDashInput
								value={this.state.selectedTable.label}
								placeholder="Gebe einen Namen ein"
								label="Tabellenname"
								name="table_name"
								type="text"
							/>
						)}
					</OneDashForm>

					<OneDashTable
						searchable={true}
						tableHeaders={this.state.selectedTableHeaders}
						tableValues={this.state.selectedTableHeaderValues}
						editable={true}
						ref={this.entryTable}
						orderable={true}
					/>
					{this.state.selectedTable.value && this.state.selectedTable.value.length > 0 && (
						<OneDashForm className="submit-form" onSubmit={this.saveTable}>
							<OneDashInput type="cancel" name="cancel" value="Abbrechen" />
							<OneDashInput type="submit" value="Eintrag Speichern" name="submit" />
						</OneDashForm>
					)}
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default TableManager;
