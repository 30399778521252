import React, { Component } from "react";
import PrintRoomInvoice from "./PrintRoomInvoice";
import RoomInvoice from "./RoomInvoice";
import { Utils } from "../../Helpers/Utils";

export interface RoomInvoiceMainProps {}

export interface RoomInvoiceMainState {}

class RoomInvoiceMain extends Component<RoomInvoiceMainProps, RoomInvoiceMainState> {
	state = { printInvoice: false };
	roomInvoiceRef = React.createRef<RoomInvoice>();
	componentDidMount() {
		window.addEventListener("hashchange", this.hashChange);
	}
	componentWillUnmount() {
		window.removeEventListener("hashchange", this.hashChange);
	}

	hashChange = (e: any) => {
		const hash = window.location.hash;
		if (hash === "#print") {
			this.printInvoice();
		} else {
			this.editInvoice();
		}
	};

	printInvoice = () => {
		const ri = this.roomInvoiceRef.current;
		if (ri) {
			ri.saveInvoice();
		}
		if (window.location.hash !== "print") window.location.hash = "print";
		this.setState({
			printInvoice: true
		});
	};
	editInvoice = () => {
		if (window.location.hash !== "") window.location.hash = "";
		this.setState({
			printInvoice: false
		});
	};
	render() {
		return (
			<>
				<nav className="dashboard-second-navigation">
					<div className="navigation-background" />

					<div className="nav-list">
						<ul>
							{!this.state.printInvoice && (
								<li>
									<button onClick={this.printInvoice} title="Zimmerrechnung drucken">
										<i className="fas fa-print" />
									</button>
								</li>
							)}
							{this.state.printInvoice && (
								<li>
									<button onClick={this.editInvoice} title="Zimmerrechnung erstellen">
										<i className="fas fa-chevron-left"></i>
									</button>
								</li>
							)}
						</ul>
					</div>
				</nav>

				<div className="room-invoice-nav" />
				{!this.state.printInvoice && <RoomInvoice loadingFinishedCallback={Utils.contentIsLoaded} ref={this.roomInvoiceRef} />}
				{this.state.printInvoice && <PrintRoomInvoice goBack={this.editInvoice} />}
			</>
		);
	}
}

export default RoomInvoiceMain;
