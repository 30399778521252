export const MAIN_SETTINGS = {
	basename: "/",

	//Sites without required Logon
	logonFreePages: ["/login", "/backend-error"],

	//Allowed Sub Pages, which shouldn't be redirected to dashboard

	allowedSubPages: ["/dashboard", "/printInvoice"]
};
