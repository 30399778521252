import React from "react";
import "./DashboardNavigation.scss";
import { NavLink } from "react-router-dom";
import { Utils } from "../../../Helpers/Utils";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import RouterHistory from "../../../Helpers/RouterHistory";
import { slide as Menu } from "react-burger-menu";
import { withRouter } from "react-router-dom";

export interface Module {
	moduleId: number;
	appId: string;
	order: number;
	name: string;
	icon: string;
	route: string;
}
export interface ApplicationNavigation {
	appId: string;
	appName: string;
	modules: Module[];
}

export interface DashboardNavigationProps {
	navLoaded: Function;
}

class DashboardNavigation extends React.Component<DashboardNavigationProps> {
	state = {
		navIsLoading: true,
		applications: [] as ApplicationNavigation[],
		navRotation: true,
		navDataReceived: false,
		width: 0,
		height: 0
	};
	timeout: any;
	navRotationTimer: any;

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	subscribeNavigationStore = () => {
		const store = window.reduxStore;
		store.subscribe(() => {
			const storeState = store.getState();
			if (storeState.navLoading !== this.state.navIsLoading) {
				clearTimeout(this.navRotationTimer);
				this.setState({
					navIsLoading: storeState.navLoading
				});
				if (storeState.navLoading) {
					this.setState({ navRotation: true });
				} else {
					this.navRotationTimer = setTimeout(() => {
						this.setState({ navRotation: false });
					}, 2500);
				}
			}
		});
	};
	componentWillUnmount() {
		clearTimeout(this.timeout);
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	componentDidMount = () => {
		//Adds Mobile navigation
		window.addEventListener("resize", this.updateWindowDimensions.bind(this));
		this.updateWindowDimensions();

		Utils.contentIsLoading();
		let path = window.location.pathname;
		path = path.replace(/\/\d+/g, "");

		this.timeout = setTimeout(() => {
			if (!this.state.navDataReceived) RouterHistory.push("/backend-error");
		}, 6000);

		BackendInterface.getDataByUrl(
			"/base/navigation",
			(data: any) => {
				data.forEach((app: any) => {
					const module = app.modules.find((x: any) => x.route === path);
					if (module) {
						Utils.setCurrentPage(module.name);
					}
				});
				window.reduxStore.dispatch({ type: "SET_USER_APPLICATION", userApplications: data });

				this.setState(
					{
						applications: data,
						navDataReceived: true
					},
					() => {
						this.props.navLoaded();
					}
				);
			},
			false
		);

		this.subscribeNavigationStore();
	};
	logout = () => {
		window.auth.logout();
	};
	buildClass = () => {
		let classList = "dashboard-navigation";
		if (!this.state.navIsLoading) {
			classList += " loading-finished";
		}
		if (!this.state.navRotation) {
			classList += " loading-stop-rotation";
		}
		return classList;
	};

	navigation = () => (
		<div className={this.buildClass()}>
			<div>
				{this.state.applications.map(app => (
					<ul key={app.appId}>
						{this.state.applications.length > 1 && <div className="app-name">{app.appName}</div>}
						{app.modules.map(module => (
							<NavigationLink
								moduleName={module.name}
								key={module.moduleId}
								to={`${module.route}/${app.appId}`}
								iconClassName={module.icon}
							/>
						))}
					</ul>
				))}
			</div>

			<ul className="nav-left-bottom">
				<li>
					<button onClick={this.logout}>
						<i className="fas fa-sign-out-alt" />
					</button>
				</li>
			</ul>
		</div>
	);

	render() {
		return (
			<>
				{this.state.navDataReceived && <>{this.state.width < 768 && <Menu>{this.navigation()}</Menu>}</>}
				{this.state.navDataReceived && this.state.width >= 768 && <> {this.navigation()}</>}
			</>
		);
	}
}

const NavigationLink = withRouter((props: any) => <NaLink {...props} />);

const NaLink = ({
	to,
	iconClassName,
	exact,
	moduleName,
	location
}: {
	to: string;
	iconClassName: string;
	exact?: boolean;
	moduleName: string;
	location: any;
}) => {
	const navLinkClicked = (e: any) => {
		if (to !== location.pathname) {
			Utils.contentIsLoading();
			Utils.setCurrentPage(moduleName);
			Utils.openScrollLock();
		} else {
			e.stopPropagation();
			e.preventDefault();
			e.nativeEvent.stopImmediatePropagation();
		}
	};
	return (
		<li>
			<NavLink onClick={navLinkClicked} exact={exact} to={to}>
				<div className="loading-indicator" />
				<i className={iconClassName} />
				<div className="navigation-route-name">{moduleName}</div>
			</NavLink>
		</li>
	);
};

export default DashboardNavigation;
