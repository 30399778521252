import React, { Component } from "react";
import { ItemProperty, languageIndicator, TItem, Zusatzstoff } from "../../Interfaces";
import "./Styles/ItemProperties.scss";
import { ALLERGENE_LIST, ZUSATZSTOFFE_LIST } from "./values";
import { OneDashTagInput, OneDashForm } from "@onedash/tools";
import { ValueLabelPair } from "@onedash/tools/dist/OneDashForm/OneDashSelect";

const ALLERGENES: ValueLabelPair[] = ALLERGENE_LIST.map((allergen, i) => {
	return {
		label: allergen.de,
		value: i
	};
});
const ZUSATZSTOFFE: ValueLabelPair[] = ZUSATZSTOFFE_LIST.map((zusatzstoff, i) => {
	return {
		label: zusatzstoff.text.de,
		value: zusatzstoff.num
	};
});

class Itemproperties extends Component<ItemProperty> {
	state = { item: {} as TItem };
	componentDidMount = () => {
		const item = this.props.item ? this.props.item : { name: { de: "DE: Text", en: "EN: Text", fr: "EN: Text" } };
		this.setState({
			item
		});
	};

	updateInputOptions = (values: { allergene: number[]; zusatzstoffe: number[] }) => {
		const item = this.state.item;
		item.allergene = [];
		item.zusatzstoffe = [];
		values.allergene.forEach(a => {
			item.allergene.push(ALLERGENE_LIST[a]);
		});
		values.zusatzstoffe.forEach(a => {
			item.zusatzstoffe.push(ZUSATZSTOFFE_LIST.find(x => x.num === a) as Zusatzstoff);
		});
		this.props.itemUpdated(item);
	};

	updateInput = (e: any) => {
		const val = e.target.value.replace(/(?:\r\n|\r|\n)/g, "<br/>");
		const lang = e.target.getAttribute("data-lang") as languageIndicator;
		const item = this.state.item as any;
		item.name[lang] = val;
		this.props.itemUpdated(item);
	};
	render() {
		return (
			<>
				{Object.keys(this.state.item).length > 0 && (
					<>
						<div className="property-close-overlay" onClick={this.props.closeComponent} />
						<div className="property-box">
							{Object.keys((this.state.item as any).name).map(lang => (
								<div className="property-textarea-container" key={lang}>
									<div className="lang-indicator">{lang}</div>
									<textarea
										onChange={this.updateInput}
										data-lang={lang}
										defaultValue={(this.state.item as any).name[lang].replace(/<br\/>/g, "\n")}
									/>
								</div>
							))}
							<OneDashForm onChange={this.updateInputOptions}>
								<OneDashTagInput
									placeholder="Allergene eingeben"
									preventDuplicates
									minQueryLength={0}
									name="allergene"
									tags={ALLERGENES}
									value={this.state.item?.allergene?.map(x => ALLERGENE_LIST.findIndex(a => a.de === x.de))}
								/>
								<OneDashTagInput
									placeholder="Zusatzstoffe eingeben"
									preventDuplicates
									minQueryLength={0}
									name="zusatzstoffe"
									tags={ZUSATZSTOFFE}
									value={this.state.item?.zusatzstoffe?.map(x => ZUSATZSTOFFE_LIST.find(a => a.num === x.num)?.num)}
								/>
							</OneDashForm>
						</div>
					</>
				)}
			</>
		);
	}
}

export default Itemproperties;
