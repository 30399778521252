import React, { Component } from "react";
import { Utils } from "../../../Helpers/Utils";
import DashboardContent from "../Dashboard/DashboardContent";
import "./Profile.scss";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { OneDashCard, OneDashForm, OneDashInput } from "@onedash/tools";
import SHA256 from "crypto-js/sha256";
import { showNotification } from "../../../Helpers/Notify";

export interface ProfileProps {}

export interface ProfileState {}

interface ProfileInterface {
	name: string;
}

interface PasswordIndicator {
	old: boolean;
	smallChar: boolean;
	bigChar: boolean;
	minNum: boolean;
	repeat: boolean;
	valid: boolean;
	number: boolean;
}
class Profile extends Component<ProfileProps, ProfileState> {
	textAreaRef = React.createRef<any>();

	state = {
		profile: {} as any,
		dialogMessage: "" as string,
		dialogTitle: "" as string,
		pwIndicator: {} as PasswordIndicator
	};
	componentDidMount = () => {
		window.auth.getProfile((profile: ProfileInterface) => {
			this.setState({
				profile
			});
			Utils.contentIsLoaded();
		});
	};

	copyAuthCode = () => {
		if (this.textAreaRef.current) {
			this.textAreaRef.current.select();
			this.textAreaRef.current.classList.remove("hidden");
			document.execCommand("copy");
			setTimeout(() => {
				this.textAreaRef.current.classList.add("hidden");
			}, 0);
		}
	};

	formRef = React.createRef<OneDashForm>();
	sendPWChange = (values: any, form: OneDashForm) => {
		if (form.validateInputs() === false) {
			return;
		}
		if (SHA256(values.oldpassword).toString() === localStorage.getItem("password")) {
			BackendInterface.postData(
				"/base/user",
				values,
				(data: any) => {
					showNotification(data);
					if (data["type"] === "S") {
						localStorage.setItem("password", SHA256(values.newpassword).toString());
						this.formRef.current && this.formRef.current.resetForm();
						setTimeout(() => {
							window.location.reload();
						}, 3000);
					}
				},
				false
			);
		} else {
			showNotification({
				type: "E",
				message: "Aktuelles Passwort stimmt nicht überein!"
			});
		}
	};

	checkNewPassword = (values: any) => {
		const p: PasswordIndicator = {
			bigChar: false,
			minNum: false,
			old: false,
			repeat: false,
			smallChar: false,
			valid: false,
			number: false
		};
		if (SHA256(values.oldpassword).toString() === localStorage.getItem("password")) {
			p.old = true;
		}
		if (values.newpassword === values.rnewpassword) {
			p.repeat = true;
		}
		if (/[a-z]/.test(values.newpassword)) {
			p.smallChar = true;
		}
		if (/[A-Z]/.test(values.newpassword)) {
			p.bigChar = true;
		}
		if (/[0-9]/.test(values.newpassword)) {
			p.number = true;
		}
		if (values?.newpassword?.length >= 8) {
			p.minNum = true;
		}
		if (p.bigChar && p.minNum && p.number && p.old && p.repeat && p.smallChar) p.valid = true;

		this.setState({ pwIndicator: p });
	};

	render() {
		const { profile } = this.state;
		const p = this.state.pwIndicator;
		if (!profile) return false;
		return (
			<DashboardContent className="profile">
				<OneDashCard title="Passwort ändern" className="password-change">
					<OneDashForm ref={this.formRef} onChange={this.checkNewPassword} onSubmit={this.sendPWChange}>
						<OneDashInput required type="password" label="Aktuelles Passwort" name="oldpassword"></OneDashInput>
						<br></br>
						<OneDashInput minLength={8} required type="password" label="Neues Passwort" name="newpassword"></OneDashInput>
						<br></br>
						<OneDashInput
							minLength={8}
							required
							type="password"
							label="Neues Passwort wiederholen"
							name="rnewpassword"></OneDashInput>

						<OneDashInput
							disabled={p.valid && p.valid === true ? false : true}
							type="submit"
							name="submit"
							value="Passwort ändern"></OneDashInput>
					</OneDashForm>
					<div className="password-policy">
						<h2>Vorraussetzungen:</h2>
						<ul>
							<li className={p.old && p.old === true ? "valid" : ""}>Aktuelles Passwort korrekt</li>
							<li className={p.number && p.number === true ? "valid" : ""}>Mindestens eine Ziffer (0 - 9)</li>
							<li className={p.bigChar && p.bigChar === true ? "valid" : ""}>Mindestens ein Großbuchstabe</li>
							<li className={p.smallChar && p.smallChar === true ? "valid" : ""}>Mindestens ein Kleinbuchstabe</li>
							<li className={p.minNum && p.minNum === true ? "valid" : ""}>Mindestens 8 Zeichen lang</li>
							<li className="info">Darf kein oft benutztes Passwort sein</li>
							<li className={p.repeat && p.repeat === true ? "valid" : ""}>Passwörter stimmen überein</li>
						</ul>
					</div>
				</OneDashCard>
				<OneDashCard title="Authorisierungscode" className="auth-code">
					<p>Hier können Sie einen Authorisierungscode Abrufen, den Sie für einige Anwendungen benötigen.</p>
					<p>Bitte rufen Sie diesen nur ab, wenn Sie explizit von uns danach gefragt werden.</p>
					<p className="danger">Diesen Code dürfen Sie niemals an Dritte weitergeben!</p>
					<input className="hidden" type="text" ref={this.textAreaRef} readOnly value={window.auth.getAccessToken()} />
					<button className="btn" onClick={this.copyAuthCode}>
						Authorisierungscode kopieren
					</button>
				</OneDashCard>
			</DashboardContent>
		);
	}
}
export default Profile;
