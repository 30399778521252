import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { OneDashCard, OneDashTable } from "@onedash/tools";

export interface DepartmentsProps {}

class Departments extends Component<DepartmentsProps> {
	state = {
		departmentCategoryValues: [] as any[],
		departmentCategoryHeaders: [] as any[],
		departmentValues: [] as any[],
		departmentHeaders: [] as any[]
	};

	componentDidMount() {
		BackendInterface.getDataByUrl(
			"/vg/departmentCategories/private",
			(data: { tableHeaders: any[]; tableData: any[] }) => {
				this.setState(
					{ departmentCategoryHeaders: data.tableHeaders, departmentCategoryValues: data.tableData },
					Utils.contentIsLoaded
				);
			}
		);
		BackendInterface.getDataByUrl("/vg/departments/private", (data: { tableHeaders: any[]; tableData: any[] }) => {
			this.setState(
				{ departmentHeaders: data.tableHeaders, departmentValues: data.tableData },
				Utils.contentIsLoaded
			);
		});
	}

	saveDepartmentCategoryEntry = (values: any, tableValues: any[]) => {
		this.setState({ departmentCategoryValues: tableValues });
		const entry = values;
		BackendInterface.postData("/vg/departmentCategory/save", entry, (response: any) => {
			if (response.type === "E") {
				const values = this.state.departmentCategoryValues;
				values.pop();
				this.setState({ departmentCategoryValues: values });
			}
		});
	};
	saveDepartmentEntry = (values: any, tableValues: any[]) => {
		this.setState({ departmentValues: tableValues });
		const entry = values;
		BackendInterface.postData("/vg/departments/save", entry, (response: any) => {
			if (response.type === "E") {
				const values = this.state.departmentValues;
				values.pop();
				this.setState({ departmentValues: values });
			}
		});
	};

	deleteDepartmentCategoryEntry = (id: number) => {
		BackendInterface.postData("/vg/departmentCategory/delete", { id }, (response: any) => {});
	};
	deleteDepartmentEntry = (id: number) => {
		BackendInterface.postData("/vg/departments/delete", { id }, (response: any) => {});
	};
	render() {
		return (
			<DashboardContent cardDisplayType="table">
				<OneDashCard title="Abteilungen" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.departmentHeaders}
						onSave={this.saveDepartmentEntry}
						onDelete={this.deleteDepartmentEntry}
						tableValues={this.state.departmentValues}></OneDashTable>
				</OneDashCard>
				<OneDashCard title="Abteilungskategorien" tableCard={true}>
					<OneDashTable
						editable={true}
						searchable={true}
						tableHeaders={this.state.departmentCategoryHeaders}
						onSave={this.saveDepartmentCategoryEntry}
						onDelete={this.deleteDepartmentCategoryEntry}
						tableValues={this.state.departmentCategoryValues}></OneDashTable>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default Departments;
