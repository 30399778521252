import React, { Component, FormEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CardDesignerNav from "./CardDesignerNav";
import "./Styles/CardDesigner.scss";
import { PrintSettings, Utils } from "../../Helpers/Utils";
import { selectValueLabelPair, AddNewMasterCardProps, masterCard } from "../../Interfaces";
import { BackendInterface } from "../../Helpers/BackendInterface";
import BasicInput from "../BasicInput/BasicInput";
import updateInputs from "../BasicInput/inputHelper";
import CardDesignerCard from "./CardDesignerCard";
import { OneDashDialog, OneDashSelect, OneDashForm, OneDashInput } from "@onedash/tools";

class CardDesigner extends Component<RouteComponentProps<{}>> {
	state = {
		selectedMasterCard: {} as masterCard,
		masterCardList: [] as masterCard[],
		selectValueLabelPairs: [] as selectValueLabelPair[],
		addNewEntry: false,
		closeable: false
	};
	masterCardSelectDialog = React.createRef<OneDashDialog>();
	masterCardSelect = React.createRef<OneDashSelect>();
	card = React.createRef<CardDesignerCard>();

	getMasterCards = async () => {
		const cards = (await BackendInterface.getAsyncDataByUrl("/cardDesigner/masterCard/get")) as masterCard[];
		Utils.contentIsLoaded();
		const selectValueLabelPairs: selectValueLabelPair[] = [];
		cards.forEach(card => {
			selectValueLabelPairs.push({ label: card.name, value: card.id });
		});
		this.setState({
			selectValueLabelPairs,
			masterCardList: cards
		});
	};
	addNewMasterCard = (card: any) => {
		BackendInterface.postData("/cardDesigner/masterCard/add", card, (resp: any) => {});
	};
	toggleMasterCardSelectionDialog = () => {
		const dialog = this.masterCardSelectDialog.current;
		if (dialog) {
			this.setState({ closeable: true });
			dialog.toggleOpen();
		}
	};

	selectMasterCard = (formValues: { masterCardSelect: string; masterCardSelectSubmit: string }, form: OneDashForm) => {
		if (form.validateInputs()) {
			const id = formValues.masterCardSelect;
			const selectedMasterCard = this.state.masterCardList.find(card => card.id === id);
			this.setState({
				selectedMasterCard
			});
			if (selectedMasterCard && this.masterCardSelectDialog.current) {
				this.masterCardSelectDialog.current.toggleOpen();
			}
		}
	};

	componentDidMount = () => {
		this.getMasterCards();
	};
	componentWillUnmount = () => {
		PrintSettings.removePrintSettings();
	};
	togglePageDetailDialog = () => {
		if (this.card.current) this.card.current.togglePageDialog();
	};
	toggleSettingsDialog = () => {
		if (this.card.current) this.card.current.toggleSettingsDialog();
	};
	render() {
		return (
			<div>
				<div className="card-designer">
					{this.state.selectedMasterCard.name && (
						<CardDesignerNav
							togglePageDetailDialog={this.togglePageDetailDialog}
							toggleMasterCardSelection={this.toggleMasterCardSelectionDialog}
							toggleSettingsDialog={this.toggleSettingsDialog}
						/>
					)}
					<CardDesignerCard ref={this.card} masterCard={this.state.selectedMasterCard} />
				</div>

				<OneDashDialog
					isOpen={!this.state.selectedMasterCard.name}
					closeByX={this.state.closeable}
					ref={this.masterCardSelectDialog}>
					{this.state.selectValueLabelPairs.length > 0 && this.state.addNewEntry === false && (
						<>
							<h2 className="dialog-headding">Kartenauswahl</h2>
							<OneDashForm onSubmit={this.selectMasterCard}>
								<OneDashSelect
									required
									label="Speisekarte auswählen"
									name="masterCardSelect"
									options={this.state.selectValueLabelPairs}
								/>
								<OneDashInput type="submit" name="masterCardSelectSubmit" value="Öffnen" />
							</OneDashForm>
						</>
					)}
					{(this.state.selectValueLabelPairs.length === 0 || this.state.addNewEntry === true) && (
						<AddNewMasterCard
							abortPossible={this.state.addNewEntry}
							onAbort={() => {
								this.setState({ addNewEntry: false });
							}}
							onSubmit={this.addNewMasterCard}
						/>
					)}
				</OneDashDialog>
			</div>
		);
	}
}

class AddNewMasterCard extends Component<AddNewMasterCardProps> {
	state = {
		cardName: ""
	};
	changeCardName = (e: any) => {
		if (e.target) {
			this.setState({
				cardName: e.target.value
			});
		}
	};
	submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const cardStyle = document.querySelector("input[name='styleSelect'][checked]") as HTMLInputElement;
		if (cardStyle && cardStyle.value) {
			const el = {
				style: cardStyle.value,
				name: this.state.cardName
			};
			this.props.onSubmit(el);
		}
	};
	componentDidMount = () => {
		updateInputs();
	};
	render() {
		return (
			<>
				<h1 className="dialog-headding">Neue Masterkarte erstellen</h1>
				<div className="basic-input">
					<form onSubmit={this.submit}>
						<BasicInput type="text" text="Name der Karte" callback={this.changeCardName} iconClass="fas fa-file-signature" />
						<OneDashSelect
							name="styleSelect"
							required={true}
							options={[
								{ label: "Große Karte", value: "standard-card" },
								{ label: "Kleine Karte", value: "drink-card" }
							]}
						/>

						<div className="flex-center flex-child-grow">
							{this.props.abortPossible && (
								<div className="form-btn">
									<i className="fas fa-backspace" />
									<button type="reset" onClick={this.props.onAbort}>
										Zurück
									</button>
								</div>
							)}
							<div className="form-btn">
								<i className="fas fa-file-medical" />
								<button>Erstellen</button>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default withRouter(CardDesigner);
