/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from "react";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { Utils } from "../../../Helpers/Utils";
import "./AbsenceManager.scss";
import { OneDashCard, OneDashTimeCalendar } from "@onedash/tools";
import dayjs from "dayjs";

interface Appointment {
	id?: number;
	timestamp_from: string | number;
	timestamp_to: string | number;
	repeatWeekly?: "1" | "0";
	description?: string;
	type?: "out-of-office" | "appointment" | "full-day";
	fullDay?: "1" | "0";
	fullDayDate?: string;
}
export interface AbsenceManagerProps {}
interface Absence {
	id?: any;
	timestamp_from: number;
	timestamp_to: number;
	userId: string;
	absence_type: string;
	absence_id: string;
	quantity_in_hours: number;
	quantity_in_days: number;
	repeat_weekly: "1" | "0";
}

const workingSchema: any = {
	numberOfDays: 7,
	workingDays: [
		{
			days: [0, 1, 2, 3, 4],
			startingHour: 8,
			startingMinute: 0,
			endHour: 12,
			endMinute: 0
		},
		{
			days: [0, 1, 2, 3],
			startingHour: 13,
			startingMinute: 0,
			endHour: 17,
			endMinute: 0
		}
	]
};

class AbsenceManager extends Component<AbsenceManagerProps> {
	state = {
		absences: [] as Appointment[]
	};
	componentDidMount() {
		BackendInterface.getDataByUrl("/user/absences", (absences: Absence[]) => {
			Utils.contentIsLoaded();
			const app = absences.map(a => {
				const appointment = {
					id: a.id,
					timestamp_from: a.timestamp_from,
					timestamp_to: a.timestamp_to,
					repeatWeekly: a.repeat_weekly
				} as Appointment;
				if (a.quantity_in_hours % 24 === 0) {
					appointment.fullDay = "1";
					appointment.fullDayDate = dayjs(a.timestamp_from).format("DD.MM.YYYY");
					appointment.type = "full-day";
				}
				return appointment;
			});
			this.setState({ absences: app });
		});
	}

	addAbsence = (appointment: Appointment) => {
		BackendInterface.postData("/user/absence/add", {
			id: appointment.id,
			type: appointment.type,
			repeatWeekly: appointment.repeatWeekly,
			from: appointment.timestamp_from,
			to: appointment.timestamp_to,
			fullDay: appointment.fullDay === "1" ? true : false
		});
	};

	removeAbsence = (appointment: Appointment) => {
		BackendInterface.postData("/user/absence/delete", {
			id: appointment.id
		});
	};
	changeAbsence = (appointment: Appointment) => {
		BackendInterface.postData("/user/absence/update", {
			id: appointment.id,
			type: appointment.type,
			repeatWeekly: appointment.repeatWeekly,
			from: appointment.timestamp_from,
			to: appointment.timestamp_to,
			fullDay: appointment.fullDay === "1" ? true : false
		});
	};

	render() {
		return (
			<DashboardContent>
				<OneDashCard maxWidth={1200} title="Termine">
					<OneDashTimeCalendar
						appointments={this.state.absences}
						changeAppointment={this.changeAbsence}
						removeAppointment={this.removeAbsence}
						addAppointment={this.addAbsence}
						workingSchema={workingSchema}
						slotMinutes={30}
					/>
				</OneDashCard>
			</DashboardContent>
		);
	}
}

export default AbsenceManager;
