import React, { Component } from "react";

export interface DashboardContentProps {
	title?: string;
	className?: string;
}

class CardWrapper extends Component<DashboardContentProps> {
	getClasses = () => {
		const p = this.props;
		let classes = "card-wrapper-content ";
		if (p.className) {
			classes += p.className;
		}

		return classes;
	};

	render() {
		return (
			<>
				<div className="card-wrapper">
					{this.props.title && <h1>{this.props.title}</h1>}
					<div className={this.getClasses()}>{this.props.children}</div>
				</div>
			</>
		);
	}
}

export default CardWrapper;
