import React, { Component } from "react";

import "./Styles/Navigation.scss";
import { CardDesignNav } from "../../Interfaces";

class CardDesignerNav extends Component<CardDesignNav> {
	render() {
		return (
			<nav className="card-designer-navigation">
				<div className="navigation-background" />

				<div className="nav-list">
					<ul>
						{/* Back to Selection */}
						<li>
							<button onClick={this.props.toggleMasterCardSelection} title="Kartenauswahl">
								<i className="fas fa-object-group" />
							</button>
						</li> 
						{/* Settings */}
						<li>
							<button onClick={this.props.toggleSettingsDialog} title="Einstellungen">
								<i className="fas fa-cogs" />
							</button>
						</li>
						{/* Pages */}
						<li>
							<button onClick={this.props.togglePageDetailDialog} title="Seitenoptionen">
								<i className="fas fa-th-large" />
							</button>
						</li>
						{/* Print */}
						<li>
							<button
								title="Drucken"
								onClick={() => {
									window.print();
								}}>
								<i className="fas fa-print" />
							</button>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

export default CardDesignerNav;
