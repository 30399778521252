import React, { Component } from "react";
import DashboardContent from "../../Allgemein/Dashboard/DashboardContent";
import { BackendInterface } from "../../../Helpers/BackendInterface";
import { Utils } from "../../../Helpers/Utils";
import { showNotification } from "../../../Helpers/Notify";
import dayjs from "dayjs";
import "dayjs/locale/de"; // load on demand
import "./PublicFacilityBookings.scss";
import { OneDashCard, OneDashBookingCalendar, OneDashTable } from "@onedash/tools";
import { tableHeader } from "@onedash/tools/dist/OneDashTable/OneDashTable";
dayjs.locale("de");

export interface PublicFacilitiesBookingsProps {}

interface PublicFacility {
	id: string;
	name: string;
	type: "Buergerhaus" | "Schutzhuette";
	bookings?: any[];
}

const bookingTableHeaders: tableHeader[] = [
	{
		title: "Status",
		columnName: "status",
		type: "text",
		visible: true,
		formattingFunction: (value, short) => {
			if (short) {
				switch (value) {
					case "confirmed":
						return <i className="fas fa-square completed"></i>;
					case "rejected":
						return <i className="fas fa-square rejected"></i>;
					case "mailConfirmed":
						return <i className="fas fa-circle pending"></i>;
					default:
						return <i className="fas fa-adjust pending"></i>;
				}
			} else {
				switch (value) {
					case "confirmed":
						return (
							<span>
								<i className="fas fa-square completed"></i> Buchung angenommen
							</span>
						);

					case "rejected":
						return (
							<span>
								<i className="fas fa-square rejected"></i> Buchung abgewiesen
							</span>
						);
					case "mailConfirmed":
						return (
							<span>
								<i className="fas fa-circle pending"></i> Auf Bürgermeister wartend
							</span>
						);
					default:
						return (
							<span>
								<i className="fas fa-adjust pending"></i> Warten auf Email - Bestätigung
							</span>
						);
				}
			}
		}
	},
	{
		title: "Name",
		columnName: "name",
		type: "text",
		visible: true
	},
	{
		title: "Tage bis",
		columnName: "daysUptoNow",
		type: "number",
		visible: true
	},
	{
		title: "Anzahl an Tagen",
		columnName: "quantityInDays",
		type: "number",
		visible: false
	},
	{
		title: "Vom",
		columnName: "fromDateFormatted",
		type: "text",
		visible: true
	},
	{
		title: "Bis",
		columnName: "toDateFormatted",
		type: "text",
		visible: true
	},
	{
		title: "Buchungsname",
		columnName: "fullName",
		type: "text",
		visible: false
	},
	{
		title: "Email",
		columnName: "email",
		type: "email",
		visible: false
	},
	{
		title: "Adresse",
		columnName: "address",
		type: "text",
		visible: false
	},
	{
		title: "Ort",
		columnName: "plzLocation",
		type: "text",
		visible: false
	},
	{
		title: "Zusätzliche Informationen",
		columnName: "additional_message",
		type: "text",
		visible: false
	}
];

class PublicFacilitiesBookings extends Component<PublicFacilitiesBookingsProps> {
	state = {
		facilities: [] as PublicFacility[]
	};
	componentDidMount() {
		BackendInterface.getDataByUrl("/vg/publicFacilities/bookings", (data: PublicFacility[]) => {
			if ((data as any).type !== "E") {
				this.setState({ facilities: data }, Utils.contentIsLoaded);
			} else {
				Utils.applicationError((data as any).message);
				Utils.contentIsLoaded();
			}
		});
	}

	addBooking = (facility: any, data: any) => {
		const facilities = this.state.facilities;
		const targetFac = facilities.find(fac => fac.id === facility.id && fac.type === facility.type);
		if (targetFac) {
			if (targetFac.bookings) {
				targetFac.bookings.push({
					id: Math.random() * 1000000,
					type: facility.type,
					...data
				});
			} else {
				targetFac.bookings = [
					{
						id: Math.random() * 1000000,
						type: facility.type,
						...data
					}
				];
			}

			this.setState({
				facilities
			});
		}
		BackendInterface.postData("/vg/publicFacilitiesBookings/add", { ...facility, ...data }, (response: any) => {
			showNotification(response);
		});
	};
	buildBookings = () => {
		let bookings = [] as any[];
		this.state.facilities.forEach(facility => {
			if (facility.bookings) {
				const facilityBookings = facility.bookings.map(booking => {
					booking.name = facility.name;
					return booking;
				});
				bookings = bookings.concat(facilityBookings);
			}
		});

		bookings.forEach(booking => {
			booking.fromDate = dayjs(Number(booking.timestamp_from));
			booking.toDate = dayjs(Number(booking.timestamp_to));
			booking.quantityInDays = booking.toDate.diff(booking.fromDate, "day") + 1;
			booking.daysUptoNow = booking.fromDate.diff(dayjs(), "day");
			booking.fromDateFormatted = booking.fromDate.format("DD.MM.YYYY");
			booking.toDateFormatted = booking.toDate.format("DD.MM.YYYY");
			booking.plzLocation = `${booking.plz} ${booking.location}`;
			if (booking.fromDateFormatted === booking.toDateFormatted) {
				booking.toDateFormatted = "";
			}
			booking.fullName = `${booking.firstName} ${booking.lastName}`;
		});
		bookings.sort((booking1, booking2) => {
			return booking1.daysUptoNow - booking2.daysUptoNow;
		});
		return bookings;
	};
	deleteBooking = (id: any) => {
		BackendInterface.postData("/vg/publicFacilitiesBookings/delete", { id }, (response: any) => {
			if (response.type === "S") {
				const facilities = this.state.facilities;
				facilities.forEach(facility => {
					if (facility.bookings) {
						const bookingIndex = facility.bookings.findIndex(booking => booking.id === id);
						facility.bookings.splice(bookingIndex, 1);
					}
				});
				this.setState({
					facilities
				});
				showNotification(response);
			}
		});
	};
	render() {
		return (
			<DashboardContent className="facility-bookings" cardDisplayType="table">
				<OneDashCard title="Gebuchte Termine">
					<OneDashTable
						onDelete={this.deleteBooking}
						searchable
						tableValues={this.buildBookings()}
						tableHeaders={bookingTableHeaders}></OneDashTable>
				</OneDashCard>
				{this.state.facilities.map(facility => (
					<OneDashCard key={facility.id} title={facility.name}>
						<OneDashBookingCalendar
							appointments={facility.bookings}
							addAppointment={data => this.addBooking({ type: facility.type, name: facility.name, id: facility.id }, data)}
							showLegend
							showDialogOnAdd
						/>
					</OneDashCard>
				))}
			</DashboardContent>
		);
	}
}

export default PublicFacilitiesBookings;
